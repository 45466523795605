import React, {Fragment} from 'react';
import { Route, Redirect } from 'react-router-dom';
 import Navbar from '../Front/Navbar';
// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
  return (
  	<Fragment>
  		<Route {...rest}  render={(props) => <Component {...props} /> } />
    </Fragment>
  )
}
 
export default PublicRoute;