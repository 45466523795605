import React from 'react';
import { Redirect } from 'react-router-dom';

import Menu from "./Menu";

import {NavigationService} from './Navigation.service';
import { removeUserSession } from '../Utils/Common';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
        token: JSON.parse(localStorage["appState"]).user.auth_token,
        user: JSON.parse(localStorage["appState"]).user,
        access: []
    };

  }
    componentDidMount() {
        if(window.location.pathname !== '/login')
        {
            if(JSON.parse(localStorage["appState"]).user.access)
            {
                this.setState( { access: JSON.parse(localStorage["appState"]).user.access });
            }

            NavigationService.getUser().then(res => {
                console.log(res);
                if(res.data.User != null)
                {
                   this.setState({ user: res.data.User, access: res.data.Access }, () => { console.log(this.state)}); 
                   // SAVE DATA TO LOCALSTORAGE
                  let appState = JSON.parse(localStorage["appState"]);
                  appState.user.access = res.data.Access;
                  localStorage["appState"] = JSON.stringify(appState);

                  // CHECK IF CURRENT URL IS NOT ON ACCESSLIST
                  var currentUrl = window.location.pathname;
                  let url_key = res.data.url_key;
                  let admin_role_access = res.data.admin_role_access;

                  if(this.isset(() => url_key[currentUrl]))
                  {
                    if(!this.isset(() => admin_role_access[url_key[currentUrl]]))
                    {
                        // alert('You have no access to view this page. Redirecting to login page.');
                        // let appState = { isLoggedIn: false, user: {} };
                        // localStorage["appState"] = JSON.stringify(appState);
                        // window.location.reload();
                        window.location.pathname = '/no/access';
                    }
                  }
                }
                // else{  window.location.pathname = '/login'; }
            }).catch(error => { window.location.pathname = '/login'; });
        }
        
    }

    isset (accessor) {
      try {
        // Note we're seeing if the returned value of our function is not
        // undefined
        return typeof accessor() !== 'undefined'
      } catch (e) {
        // And we're able to catch the Error it would normally throw for
        // referencing a property of undefined
        return false
      }
    }

  logout() {
    let appState = { isLoggedIn: false, user: {} };
    removeUserSession();
    localStorage["appState"] = JSON.stringify(appState);

    return <Redirect to='/login' />;
  }
  
  render() {
    if(window.location.pathname != '/login' )
    {   
        document.body.classList.add('vertical-layout');
        return (
          <React.Fragment>  
             <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-dark">
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div className="collapse navbar-collapse show" id="navbar-mobile">
                            <ul className="nav navbar-nav mr-auto float-left">
                                <li className="nav-item mobile-menu d-md-none mr-auto"><a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i className="ft-menu font-large-1"> </i></a></li>
                                
                            </ul>
                            <ul className="nav navbar-nav float-right">
                                <li className="dropdown dropdown-user nav-item"><a className="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown"> <span className="avatar avatar-online"><img src="/app-assets/images/portrait/small/avatar-s-19.png" alt="avatar" /><i></i></span></a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <div className="arrow_box_right"><a className="dropdown-item" href="#"><span className="avatar avatar-online"><span className="user-name text-bold-700 ">{this.state.user.admin_name}</span></span></a>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item" href="user-profile.html"><i className="ft-user"></i> Edit Profile</a>
                                            <div className="dropdown-divider"></div><a className="dropdown-item" href="/login" onClick={this.logout} ><i className="ft-power"></i> Logout</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav> 
            <Menu currentUser={this.props.currentUser} access={this.state.access} />
          </React.Fragment>            
        );
    }
    else
    {
        return ( <span></span> );
    }
    
  }
}

export default Navigation;
