import http from '../../Services/api.laravel.http';
import $ from "jquery";
var qs = require('qs');
export const serviceApi = {
  list: (service) => http.get('/subscription/service', {params: service,paramsSerializer: params => {return qs.stringify(params) }}),
  saveService: (service) => http.post('/subscription/service', service),
  getService: (_id) => http.get('/subscription/service/' + _id),
  updateService: (_id, service) => http.put('/subscription/service/' + _id, service),
  deleteService: (_id) => http.delete('/subscription/service/' + _id),
}


export const createButtonService = (event)=>{
	event.setState((prevState) => {  
     const newState = { 
       mode: 'create', 
       _id: '',
       form_error: [],
     };
     return newState 
   });
};

export const handleChange = (event, input_name) => input_ => {

	var input_value = input_.target.value;

	event.setState((prevState) => {  
     const newState = { 
       [input_name]: input_value
     };
     return newState 
   });
};

export const handleChangeNewTier = (event, i, input_name) => input_ => {

  var input_value = input_.target.value;
  var newTier = event.state.newTier;

  newTier[i][input_name] = input_value;
  event.setState((prevState) => {  
     const newState = { 
       newTier: newTier,
     };
     return newState 
   });
};

export const handleChangeNewTierWYSYG = (event, i, input_name) => input_ => {

  var input_value = input_;
  var newTier = event.state.newTier;

  newTier[i][input_name] = input_value;
  event.setState((prevState) => {  
     const newState = { 
       newTier: newTier,
     };
     return newState 
   });
};

export const addNewTier = (event) => {
  const newTier = event.state.newTier;
  const AddNew = { name: '', price: 0, role: '', extra_price : 0, description: '', service_type: 'PUBLIC', service_code: ''};

  newTier.push(AddNew);

  event.setState((prevState) => {  
     const newState = { 
       newTier: newTier, 
     };
     return newState 
   });
}

export const removeTier = (event, newTierKey) => {

  const newTier = event.state.newTier;

  newTier.splice(newTierKey, 1);

  event.setState((prevState) => {  
     const newState = { 
       newTier: newTier, 
     };
     return newState 
   });
}

export const saveService = (event) => {

  console.log(event.state);
  console.log('saveService');
  $(".btn-save-service").attr("disabled", "disabled").html("Saving Role...");

  let postParams = {
    service_name: event.state.service_name,
    service_price: event.state.service_price,
    service_discount: event.state.service_discount,
    service_show_in: event.state.service_show_in
  };
  
  serviceApi.saveService(postParams).then(r => {
    if (r.data.status == 1) 
    {
      this.setState({ form_error: [], success_message: r.data.message });
      $(".btn-close-modal-create-role").click();

      let resetInput = {
        role_name: '',
        role_description: '',
        checked: [],
        _id: ""
      };

      // this.setState(resetInput);

    } else 
    {
      // this.setState({ form_error: r.data.errors, success_message: "" });
    }
    $(".btn-save-service").removeAttr("disabled").html("Save");

    // this.getServiceRoleList();
  }).catch(error => {
    
    $(".btn-save-service").removeAttr("disabled").html("Save");
    let errors = ["Network error, please reload the page"];

    // this.setState({ form_error: errors, success_message: "" });
  });

};