import http from '../../Services/api.laravel.http';
import $ from "jquery";
var qs = require('qs');

export const systemAccessApi = {
  list: (access) => http.get('/subscription/system/access',{params: access,paramsSerializer: params => {return qs.stringify(params) }} ),
  saveSystemAccess: (access) => http.post('/subscription/system/access', access),
  getSystemAccess: (_id) => http.get('/subscription/system/access/' + _id),
  saveSystemAccessUpdate: (access, _id) => http.put('/subscription/system/access/' + _id, access),
  saveSystemAccessDelete: (_id) => http.delete('/subscription/system/access/' + _id ),
  syncSystemAccess: (access) => http.post('/subscription/system/access/sync/default', access),
}
