import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import PropTypes from 'prop-types';
import {roleApi} from './Role.service';
import CheckboxTree from "react-checkbox-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCheckSquare, faSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile } from "@fortawesome/free-solid-svg-icons";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-checkbox-tree/src/less/react-checkbox-tree.less";
import "react-checkbox-tree/src/scss/react-checkbox-tree.scss";

class DeleteRoleModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.closeModal = this.closeModal.bind(this );
    this.state = {
      form_error: [],
      show: false,
      loading: false,
      role_name: '',
      role_description: '',
      _id: '',
      errors: [],
      nodes : [],
      checked : [],
      expanded : [],
      default_expanded: [],
      role : []
    };
  }

  static propTypes = {
    getRoleList: PropTypes.func.isRequired,
  }

  openModalDelete(role) {
    this.setState({ show: true, loading: true, role: role});

    this.setState({ loading: false});
  }
  closeModal() { this.setState({ show: false }); }
  handleChange = prop => event => { this.setState({ [prop]: event.target.value }); };

  deleteRole()
  {
    $(".btn-save-create-role").attr("disabled", "disabled").html("Saving Role...");
    this.setState({ loading: true});
    roleApi
      .deleteRole(this.state.role._id)
      .then(r => {
        if (r.data.status == 1) 
        {
          this.setState({form_error: [], success_message: r.data.message, role_name: '', role_description: '',  show: false});
        } 
        else 
        {
          this.setState({ form_error: r.data.errors, success_message: "", loading: false });
        }

        $(".btn-save-create-role").removeAttr("disabled").html("Save");

        this.props.getRoleList();
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "", loading: false });
      });

  }

  render() {
    return (
      <div>
        <Modal size="sm" show={this.state.show} onHide={this.closeModal} >
          <Modal.Header closeButton> <Modal.Title>Confirm Remove Role</Modal.Title> </Modal.Header>
          {this.state.loading ? ( <Spinner /> ) : 
            <React.Fragment>
            <Modal.Body>
            
              
                <div className='row'>
                  <div className="col-md-12">
                      Confirm remove role "{this.state.role.admin_role}"? <br /><small>Upon removing all user using this role will be unable to use the system/s</small>
                  </div>
                </div>
                 {this.state.form_error.map((errors, index) => (
                  <React.Fragment key={index}>
                  <div className="col-md-12">
                    <span className="text-danger">{errors}</span>{" "}
                  </div>
                  </React.Fragment>
                ))}
                
              
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger btn-save-create-role" onClick={event => {this.deleteRole();}}>Delete</button>
            </Modal.Footer>
          </React.Fragment> 
          } 
        </Modal>
      </div>
    );
  }
}
export default DeleteRoleModal;
