import React from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import Home from "./Tabs/Dashboard/Home";
import AdminAccount from "./Tabs/Admin/Account";
import AdminRole from "./Tabs/Admin/Role";
import AdminRoleLevel from "./Tabs/Admin/RoleLevel";
import Service from "./Tabs/Subscription/Service";
import ServiceRole from "./Tabs/Subscription/Role";
import SystemAccess from "./Tabs/Subscription/SystemAccess";
import NoAccess from "./Tabs/NoAccess/NoAccess";
import Subscribers from "./Tabs/Subscription/Subscribers";
import Navigation from './Includes/Navigation';
import Login from "./Login";
import PublicRoute from './Utils/PublicRoute';
import AdminRoute from './Utils/AdminRoute';

import Register from "./Front/Register";
import Profile from "./Front/Profile";
import Services from "./Front/Services";
import Payment from "./Front/Payment";
import Complete from "./Front/Complete";
import SubscriptionPage from "./Front/SubscriptionPage";



class App extends React.Component {
  constructor(props) {
    super(props);

    if(localStorage["appState"])
    {
      if(JSON.parse(localStorage["appState"]).user)
      {
        this.state = {
          isLoggedIn: true,
          user: JSON.parse(localStorage["appState"]).user
        };
      }
      else
      {
        this.state = {
          isLoggedIn: false,
          user: {}
        };
      }
    }
    else
    {
      this.state = {
        isLoggedIn: false,
        user: {}
      };

      let user ={
        auth_token: 'NOTOKEN'
      }
      let appState = {
        isLoggedIn: false,
        user: user,
        _email: '',
        password: ''
      };
      localStorage["appState"] = JSON.stringify(appState);
    }
    
  }




  componentDidMount() {
    let state = localStorage["appState"];
    if (state) {
      let AppState = JSON.parse(state);
      this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState });
    }
  }

  render() {

    // Redirect if not logged in

    return (
      <Switch data="data">
        <div>
          
          <PublicRoute path="/login" exact component={Login}/>
          <PublicRoute path="/register" exact component={Register}/>
          <PublicRoute path="/profile" exact component={Profile}/>
          <PublicRoute path="/services" exact component={Services}/>
          <PublicRoute path="/payment" exact component={Payment}/>
          <PublicRoute path="/complete" exact component={Complete}/>
          <PublicRoute path="/subscriptions" exact component={SubscriptionPage}/>
          <PublicRoute path="/" exact component={SubscriptionPage}/>

          <AdminRoute component={Home} restricted={true} path="/dashboard" user={this.state.user} exact />
          <AdminRoute component={AdminAccount} restricted={true} path="/admin/account" user={this.state.user} exact />
          <AdminRoute component={AdminRole} restricted={true} path="/admin/role" user={this.state.user} exact />
          <AdminRoute component={AdminRoleLevel} restricted={true} path="/admin/level" user={this.state.user} exact />
          <AdminRoute component={Subscribers} restricted={true} path="/subscription/subscribers" user={this.state.user} exact />
          <AdminRoute component={Service} restricted={true} path="/subscription/services" user={this.state.user} exact />
          <AdminRoute component={ServiceRole} restricted={true} path="/subscription/role" user={this.state.user} exact />
          <AdminRoute component={SystemAccess} restricted={true} path="/subscription/system/access" user={this.state.user} exact />
          <AdminRoute component={NoAccess} exact path="/no/access" restricted={true} user={this.state.user} />
          
        </div>  
      </Switch>
    );
  }
}

const AppContainer = withRouter(props => <App {...props} />);
render(
  <BrowserRouter>
    <AppContainer />
  </BrowserRouter>,

  document.getElementById("root")
);
