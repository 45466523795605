import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common';
import Navigation from '../Includes/Navigation';

const AdminRoute = ({component: Component, ...rest}) => {
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            true ?
                <Fragment><Navigation /><Component {...props} /></Fragment>
            : <Redirect to="/login" />
        )} />
    );
};

export default AdminRoute;