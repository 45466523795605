import React from "react";
import axios from "axios";
import {subscriberAPI} from './Subscribers.service';
import CreateSubscriberModal from './Subscribers.modal.create';
import EditSubscriberModal from './Subscribers.modal.edit';
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import Pagination from "react-js-pagination";
import Parser from 'html-react-parser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export default class Subscribers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      search: "",
      loading: true,
      service_access_list: [],
      service_access_list_paginate: [],
      success_message: '',
      loading: true,
      _id: '',
      subscribers : [],
      subscribers_paginate: [],
      _remove: []
    };

  }

  CreateSubscriberModalRef = ({handleShowCreateSubscriberModal}) => {
     this.showModal = handleShowCreateSubscriberModal;
  }

  DeleteSystemAccessModalRef = ({handleShowDeleteAccessModal}) => {
     this.showModalDelete = handleShowDeleteAccessModal;
  }

  EditSubcriptionModalRef = ({handleShowEditSubscriberModal}) => {
     this.showModalEdit = handleShowEditSubscriberModal;
  }

  onDeleteModalOpenClick =  (_id) => {
    this.showModalDelete(_id);
   }

  onEditModalOpenClick =  (_id) => {
    this.showModalEdit(_id);
   }


  onCreateModalOpenClick = () => {
    this.showModal();
   }

   confirmDeleteRole = subscriber => {
    this.setState({'_remove': subscriber});
  };



   syncServiceRoleApi =  (key, _id) => {

    let system_access = this.state.service_access_list;
    system_access[key].syncing = true;
    this.setState({ service_access_list: system_access });

    let postParams = { id: _id};

    subscriberAPI
      .syncSystemAccess(postParams)
      .then(r => {
        system_access[key].syncing = false;
        this.setState({ service_access_list: system_access });
      })
      .catch(error => {
        alert("Something went wrong, please reload the page");
        this.setState({ loading: false });
      });

    this.setState({ service_access_list: system_access });
   }


   getSubscriberList = async () => {
    this.setState({ loading: true });
    subscriberAPI
      .list({ page: this.state.activePage, search: this.state.search })
      .then(r => {
        console.log(r);
        if (r.data.status == 1) {

          console.log(r.data);

          this.setState({
            subscribers: r.data.Subscribers.data,
            subscribers_paginate: r.data.Subscribers,
            loading: false
          });
        }
      })
      .catch(error => {
        alert("Something went wrong, please reload the page");
        this.setState({ loading: false });
      });
  }

  handleSearch = prop => event => {
    this.setState(
      {
        search: event.target.value,
        activePage: 1,
        'success_message': ''
      },
      () => {
        this.getSubscriberList();
      }
    );
  };

  handlePageChange = prop => event => {
    this.setState(
      {
        activePage: event,
        'success_message': ''
      },
      () => {
        this.getSubscriberList();
      }
    );
  };

  deleteSubscriber =  event =>{
    $(".btn-delete-subscription").attr("disabled", "disabled").html("Removing...");

      subscriberAPI
      .saveSubscriberDelete(this.state._remove._id)
      .then(r => {
        this.setState({_remove: [], success_message: r.data.message});
        $(".btn-close-modal-delete-service").click();
        $(".btn-delete-subscription").removeAttr("disabled").html("Remove");
        this.getSubscriberList();
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "" });
        $(".btn-delete-subscription").removeAttr("disabled").html("Remove");
      });

  }

   componentDidMount() {
     this.getSubscriberList();
   }

  render() {
    return (
      <React.Fragment>
       <div className="app-content content">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header row">
                  <div className="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Subscribers</h3>
                      <div className="breadcrumbs-top d-inline-block">
                          <div className="breadcrumb-wrapper mr-1">
                              <ol className="breadcrumb">
                                  <li className="breadcrumb-item"><a href="/subscription/subscribers">List</a></li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <div className="content-header-right col-md-4 col-12 d-block "><a className="btn btn-primary btn-min-width float-right box-shadow-4 mr-1 mb-1" data-toggle="modal" data-target="#new-service-modal" href="#" onClick={this.onCreateModalOpenClick} ><i className="ft-plus"></i>Create Subscriber</a></div>
              </div>
              <div className="content-body">
                  <section className="row">
                      <div className="col-sm-12">
                          <div id="what-is" className="card">
                              <div className="card-header">
                                  <h4 className="card-title">Subscriber Management</h4>
                                  <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                              </div>
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                      <div className="card-text table-responsive">
                                          <table className="table table-bordered table-striped table-hover table-striped table-condensed ">
                                            <thead>
                                              <tr>
                                                <th colSpan="20">
                                                  <div className="col-sm-3 col-md-3 float-md-right">
                                                    <input
                                                      type="text"
                                                      className="form-control input-sm"
                                                      placeholder="Search.."
                                                      onChange={this.handleSearch()}
                                                    />
                                                  </div>
                                                </th>
                                              </tr>
                                              <tr className="text-center">
                                                <th>Basic Info</th>
                                                <th>Company Info</th>
                                                <th>Username</th>
                                                <th>Service/Tier</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.loading ? (
                                                <tr><td colSpan="20"><Spinner /></td></tr>
                                                            ) :
                                               <React.Fragment>
                                                {Object.keys(this.state.subscribers).length == 0? <tr><td colSpan="20"><center>---No data---</center></td></tr> :null }
                                                {this.state.subscribers.map((list, key) => (
                                                  <React.Fragment key={list._id}>
                                                   <tr>
                                                      <td>
                                                        <small>
                                                          <table className='table table-sm'>
                                                            <tbody>
                                                              <tr><td><b>Name</b></td><td>{list.admin_name}</td></tr>
                                                              <tr><td><b>Email (notifcation)</b></td><td>{list.email_notification}</td></tr>
                                                              <tr><td><b>Contact #</b></td><td>{list.contact_number}</td></tr>
                                                              <tr><td><b>Billing Address</b></td><td>{list.billing_address}</td></tr>
                                                              <tr><td><b>Birthday</b></td><td>{list.subscriber_birthday}</td></tr>
                                                            </tbody>
                                                          </table>
                                                        </small>
                                                      </td>
                                                      <td>
                                                        <small>
                                                          <table className='table table-sm'>
                                                            <tbody>
                                                              <tr><td><b>Company</b></td><td>{list.company_name}</td></tr>
                                                              <tr><td><b>Address</b></td><td>{list.company_address}</td></tr>
                                                            </tbody>
                                                          </table>
                                                        </small>
                                                      </td>
                                                      <td>{list.admin_email}</td>
                                                      <td>{list.subscriber.service.service_name} ({list.subscriber.tier.tier_name})</td>
                                                      <td>
                                                        {list.syncing ? ( <Spinner /> ) : 
                                                          <React.Fragment>
                                                            <button className="btn btn-primary" onClick={event => { this.onEditModalOpenClick(list._id); }} data-toggle="modal" data-target="#new-service-modal">
                                                              <i className="ft-edit"></i>
                                                            </button>
                                                            &nbsp;
                                                            <button className="btn btn-danger" onClick={event => { this.confirmDeleteRole(list); }} data-toggle="modal" data-target="#remove-subscription-modal">
                                                              <i className="ft-trash"></i>
                                                            </button>
                                                          </React.Fragment>
                                                        }
                                                        
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                ))}
                                                </React.Fragment>
                                              }



                                              <tr>
                                                <td colSpan="20">
                                                  <div className="d-flex justify-content-center">
                                                    <Pagination
                                                      activePage={this.state.activePage}
                                                      itemsCountPerPage={10}
                                                      totalItemsCount={this.state.subscribers_paginate.total}
                                                      pageRangeDisplayed={10}
                                                      onChange={this.handlePageChange()}
                                                      itemClass={"page-item"}
                                                      linkClass={"page-link"}
                                                      className={"pagination"}
                                                    />
                                                  </div>  
                                                </td>
                                              </tr>
                                            </tbody>

                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
        </div>

        <div id="remove-subscription-modal" className="modal" tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-dialog-centered modal-md"role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Remove Subscription</h5>
                <button type="button" className="close btn-close-modal-delete-service" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <center>Are you sure you want to remove "{ this.state._remove.admin_name}". Upon removing, all accounts attached to this subscription will be disabled</center>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-delete-subscription"  onClick={event => { this.deleteSubscriber(); }} >Confirm</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>   


        <CreateSubscriberModal  ref={this.CreateSubscriberModalRef}   getSubscriberList={this.getSubscriberList} ></CreateSubscriberModal>
        <EditSubscriberModal  ref={this.EditSubcriptionModalRef}     getSubscriberList={this.getSubscriberList} ></EditSubscriberModal>

        </React.Fragment>
    );
  }
}