// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
}

export const setUserPermissions = (access) =>{
  localStorage.setItem('permssions', JSON.stringify(access));
}

export const setUserAccess = (access) =>{
  localStorage.setItem('access', JSON.stringify(access));
}

export const getUserAccess = () => {
	// return JSON.parse(sessionStorage.getItem('access')) || null;
  const access = localStorage.getItem('access');
  if (access) return JSON.parse(access);
  else return null;
}

export const getUserPermissions = () => {
  // return JSON.parse(sessionStorage.getItem('access')) || null;
  const permssions = localStorage.getItem('permssions');
  if (permssions) return JSON.parse(permssions);
  else return null;
}


// react-select custom style mini select
export const reactSelectStyle = {
  control: (base, state) => ({
    ...base,
    height: 30,
    minHeight: 30
  }),
  dropdownIndicator: base => ({
          ...base,
          padding: 2
      }),
};


// truncate stirng greater than 50
export const truncateString = (longString) => {
    let string;
 
    try {

        if(longString.length > 50)
        { 
          string = longString.substr(0,30) + ' ...'
        }
        else
        {
            string = longString
        }

        return string;

    } catch (error) {
      
        console.log(error)
    }


}

export const numberFormat = (number, decimal = 2, comma = true) => {
  if(comma){
    return Number(parseFloat(number).toFixed(decimal)).toLocaleString('en', {
        minimumFractionDigits: decimal
    });
  }
  else{
    return Number(parseFloat(number).toFixed(decimal));
  }
}

export const dateForm = (date, string = false) => {
  
  if(string)
  {
    var dateObj = new Date(date);
    return dateObj.toString();
  }
  return new Date(date);
}

export const checkForDuplicates = (array, keyName) => {
  return new Set(array.map(item => item[keyName])).size !== array.length;
}


export const formattedDate = () => {
  let current_datetime = new Date();
  let formatted_date = ('0' + (current_datetime.getMonth()+1)).slice(-2) + '-' + ('0' + current_datetime.getDate()).slice(-2) + '-' + current_datetime.getFullYear()
  
  return formatted_date;
}


export const capitalize = (stringValue) => {
  if (typeof stringValue !== 'string') return ''
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1)
}

export const getFileExtension = (filename) => {
  return filename.substring(filename.lastIndexOf('.') +1, filename.length) || filename;
}

// fileExtensionIcon display
export const fileExtensionIcon = (filename) => {

  let fileExtension = getFileExtension(filename);
  // console.log(fileExtension)
  switch (fileExtension) {
      case 'doc':
          return '/file-types/doc.png';
          break;
      case 'docx':
          return '/file-types/docx.png';
          break;
      case 'pdf':
          return '/file-types/pdf.png';
          break;
      case 'ppt':
          return '/file-types/ppt.png';
          break;
      case 'xls':
          return '/file-types/xls.png';
          break;
      case 'xlsx':
          return '/file-types/xls.png';
          break;
      case 'csv':
          return '/file-types/csv.png';
          break;
      case 'jpg':
          return '/file-types/jpg.png';
          break;
      case 'jpeg':
          return '/file-types/jpg.png';
          break;
      case 'png':
          return '/file-types/png.png';
          break;
      default:
          return '/file-types/fail.png';
          break;
  }
}


// this prevents from typing negative number, text, including "e"
export const onlyNumber = (evt) => {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
}