import React from "react";
import axios from "axios";
import {accountsAPI} from './Account.service';
import CreateUserModal from './Account.modal.create';
import EditUserModal from './Account.modal.edit';
import DeleteUserModal from './Account.modal.delete';
import Spinner from "../../Includes/Spinner";
import Pagination from "react-js-pagination";


const API_ROOT = process.env.REACT_APP_API_LINK || 'inventorylumen.test/api';

export default class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: JSON.parse(localStorage["appState"]).user.auth_token,
      users: [],
      loading: false,
      activePage: 1,
      search: '',
      user_paginate: []
    };
  }

  CreateUserModalRef = ({handleShowCreateUserModal}) => { this.openModalCreateUser = handleShowCreateUserModal; }
  EditUserModalRef = ({handleShowEditUserModal}) => { this.openModalEditUser = handleShowEditUserModal; }
  DeleteUserModalRef = ({handleShowDeleteUserModal}) => { this.openModalDeleteUser = handleShowDeleteUserModal; }
  
  openModalCreateUser_ = () => { this.openModalCreateUser(); }
  openModalEditUser_ = (user) => { this.openModalEditUser(user); }
  openModalDeleteUser_ = (user) => { this.openModalDeleteUser(user); }

   getUserList = async () => {

     this.setState({ loading: true });
    accountsAPI.list({ page: this.state.activePage, search: this.state.search }).then( r => {
      if(r.data.users)
      {
        this.setState({ users: r.data.users.data, loading: false, user_paginate: r.data.users });
      }
    })
   }

   handleSearch = prop => event => {
    this.setState(
      {
        search: event.target.value,
        activePage: 1,
        'success_message': ''
      },
      () => {
        this.getUserList();
      }
    );
  };




  handlePageChange = prop => event => {
    this.setState(
      {
        activePage: event,
        'success_message': ''
      },
      () => {
        this.getUserList();
      }
    );
  };

  componentDidMount() {
    let users = [];

    this.getUserList();

  }

  render() {
    return (
      <React.Fragment>  
        <div className="app-content content">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header row">
                  <div className="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Admin</h3>
                      <div className="breadcrumbs-top d-inline-block">
                          <div className="breadcrumb-wrapper mr-1">
                              <ol className="breadcrumb">
                                  <li className="breadcrumb-item"><a href="/admin/account">Accounts</a></li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <div className="content-header-right col-md-4 col-12 d-block "><a className="btn btn-primary btn-min-width float-right box-shadow-4 mr-1 mb-1"  href="#" onClick={this.openModalCreateUser_}><i className="ft-plus"></i> Create Account</a></div>
              </div>
              <div className="content-body">
                  <section className="row">
                      <div className="col-sm-12">
                          <div id="what-is" className="card">
                              <div className="card-header">
                                  <h4 className="card-title">Admin Accounts Management</h4>
                                  <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                              </div>
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                      <div className="card-text table-responsive">
                                          <table className='table table-bordered table-hover table-striped table-condensed table-sm'>
                                            <thead>
                                              <tr>
                                                <th colSpan="20">
                                                  <div className='col-sm-3 col-md-3 float-md-right'><input type="text" onChange={this.handleSearch()} className='form-control input-sm' placeholder="Search.." /></div>
                                                </th>
                                              </tr>
                                              <tr className='text-center'>
                                                <th>Name</th>
                                                <th>Birthday</th>
                                                <th>Contact</th>
                                                <th>Username</th>
                                                <th>Admin <br />Role</th>
                                                <th></th>
                                              </tr>
                                            </thead>  
                                            <tbody className={ this.state.loading ? '' : 'd-none'} >
                                              <tr  >
                                                <td colSpan="20">
                                                  <Spinner />
                                                </td>
                                              </tr>
                                            </tbody> 
                                            <tbody className={ this.state.loading ? 'd-none' : ''}>
                                            {Object.keys(this.state.users).length == 0? <tr><td colSpan="20"><center>---No data---</center></td></tr> :null }
                                              {this.state.users.map((user, i) => (  
                                                  <tr key={i}>
                                                    <td>{user.admin_name}</td>
                                                    <td>{user.subscriber_birthday}</td>
                                                    <td>{user.contact_number}</td>
                                                    <td>{user.admin_email}</td>
                                                    <td>{user.role.admin_role}</td>
                                                    <td className=''>
                                                      <button className="btn btn-primary margin-5" onClick={event => { this.openModalEditUser_(user); }} data-toggle="modal" data-target="#new-service-modal">
                                                        <i className="ft-edit"></i>
                                                      </button>
                                                      &nbsp;
                                                      <button className="btn btn-danger margin-5" onClick={event => { this.openModalDeleteUser_(user); }} data-toggle="modal" data-target="#remove-service-role-modal">
                                                        <i className="ft-trash"></i>
                                                      </button>
                                                    </td>
                                                  </tr>  
                                                ))} 

                                              <tr>
                                                <td colSpan="20">
                                                <div className="d-flex justify-content-center">
                                                  <Pagination activePage={this.state.activePage} itemsCountPerPage={10} totalItemsCount={this.state.user_paginate.total} pageRangeDisplayed={10} onChange={this.handlePageChange("role_name")} itemClass={"page-item"} linkClass={"page-link"} className={"pagination"} />
                                                </div>  
                                                </td>
                                              </tr>
                                            </tbody> 
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
        </div>

        <CreateUserModal  ref={this.CreateUserModalRef} getUserList={this.getUserList} ></CreateUserModal>
        <EditUserModal  ref={this.EditUserModalRef} getUserList={this.getUserList} ></EditUserModal>
        <DeleteUserModal  ref={this.DeleteUserModalRef} getUserList={this.getUserList} ></DeleteUserModal>



      </React.Fragment>    
    );
  }
}