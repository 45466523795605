import http from '../../Services/api.laravel.http';
var qs = require('qs');

export const role = {
  list: (p) => http.get('/subscription/role', {
	  params: p,
	  paramsSerializer: params => {
	    return qs.stringify(params)
	  }
	}), 
  defaultAccess: () => http.get('/subscription/role/default/access'),
  saveRole: (role) => http.post('/subscription/role', role),
  getRole: (id) => http.get('/subscription/role/' + id),
  saveRoleEdit: (role) => http.put('/subscription/role/' + role._id, role),
  deleRole: (id) => http.delete('/subscription/role/' + id)
}