import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { systemAccessApi } from "./SystemAccess.service";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import PropTypes from 'prop-types';
import {serviceApi, createButtonService, handleChange, addNewTier, saveService, removeTier, handleChangeNewTier, handleChangeNewTierWYSYG} from './Service.service';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class CreateServiceModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowCreateServiceModal = this.handleShowCreateServiceModal.bind(
      this
    );
    this.handleCloseCreateServiceModal = this.handleCloseCreateServiceModal.bind(
      this
    );
    this.state = {
      form_error: [],
      show: false,
      _id: '',
      'service_name': '',
      'service_name' : '',
      'service_price' : 0,
      'service_discount': 0,
      'service_show_in': 'public',
      'service_paypal_product': null,
      'newTier': [
        { name: '', price: 0, role: '', extra_price : 0, description: '', service_type: 'PUBLIC', service_code: ''}
      ],
      'roles' : [],
      loading: false,
      errors: []
    };
  }

  static propTypes = {
    getServicesList: PropTypes.func.isRequired,
  }

  componentWillReceiveProps(newProps) {

    let newTier = this.state.newTier;

    newTier.map((tier, key) => {
      if(tier.role == '')
      {
        newTier[key].role = newProps.first_role;
      }
    });

    // console.log(newProps);
    // if(newProps.first_paypal_product !== undefined)
    // {
    //   this.setState({service_paypal_product: this.newProps.first_paypal_product});
    // }
  }

  handleShowCreateServiceModal() {
    this.setState({ show: true });
  }
  handleCloseCreateServiceModal() {
    this.setState({ show: false });
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };


  saveService = event => {
    $(".btn-save-create-service")
      .attr("disabled", "disabled")
      .html("Creating...");

    this.setState({ loading: true });
    let postParams = {
      service_name: this.state.service_name,
      service_paypal_product: this.state.service_paypal_product,
      service_show_details: this.state.service_show_details,
      service_tier: this.state.newTier,
    };

    serviceApi
      .saveService(postParams)
      .then(ret => {
        if (ret.data.status == 1) {

          this.setState({
            'service_name': '',
            'service_show_details' : 0,
            'service_discount': 0,
            'service_show_in': 'public',
            'newTier': [
              { name: '', price: 0, role: '', extra_price: 0, description: '', service_type: 'PUBLIC', service_code: ''}
            ],
            loading: false,
            show: false
          });
          this.props.getServicesList();
          $(".btn-save-create-service").removeAttr("disabled").html("Save");
        } else 
        {
          this.setState({ form_error: ret.data.errors, success_message: "", loading: false });
          this.props.getServicesList();
        }
        $(".btn-save-create-service").removeAttr("disabled").html("Save");
      })
      .catch(error => {
        console.log(error);
        let errors = ["Network error, please reload the page or you have entered an invalid API"];
        this.setState({ form_error: errors, success_message: "" , loading: false});
        $(".btn-save-create-service").removeAttr("disabled").html("Save");
      });
  };



  render() {

    if(this.state.service_paypal_product == ""){
      if(this.props.paypal_products){
        if(this.props.paypal_products[0]){
          this.setState({service_paypal_product : this.props.paypal_products[0]._id});
        }
      }
    } 

    return (
      <div>
        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleCloseCreateServiceModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.loading ? ( <Spinner /> ) : 
            <React.Fragment>
              <div className='row'>
                <div className='col-md-12 row'>
                  <div className='col-md-6'>
                    <label>Name</label>
                    <input type='text' className='form-control' value={ this.state.service_name } onChange={ handleChange(this, 'service_name') } />
                  </div>
                  <div className='col-md-6'>
                    <label>Paypal Product</label>
                    <select className='form-control' value={ this.state.service_paypal_product } onChange={ handleChange(this, 'service_paypal_product') } >
                      {this.props.paypal_products.map((product, key) => (
                         <option value={product._id}>{product.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='col-md-12'  value={ this.state.service_show_details } onChange={ handleChange(this, 'service_show_details') } >
                    <label>Description</label>
                    <textarea className='form-control'></textarea>
                  </div>
                </div>
                <div className='col-md-12 row'>
                  <div className='col-md-12 table-responsive'>
                    <br />
                    <small>
                      <table className='table table-condensed table-sm table-striped table-bordered' >
                        <thead>
                          <tr>
                            <th colSpan="20" className="five-padding">
                              <center>Paypal Subscription/s</center>

                            </th>
                          </tr>
                        </thead>
                        <tbody>
                         {this.state.newTier.map((tier, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td>
                                <div class='row'>
                                  <div class="col-md-6">
                                    <label>Name:</label>
                                    <input type='text' className='form-control'  value={tier.name} onChange={ handleChangeNewTier(this, i, 'name') } />
                                  </div>
                                  <div class="col-md-6">
                                    <label>Price:</label>
                                    <input type='number' className='form-control' value={tier.price} onChange={ handleChangeNewTier(this, i, 'price') }  />
                                  </div>
                                  <div class="col-md-6">
                                    <label>Role:</label>
                                    <select className='form-control' onChange={ handleChangeNewTier(this, i, 'role') } >
                                      {this.props.roles.map((r, key) => (
                                        <React.Fragment key={(i + 1) + (key + 1) * 100}>
                                         <option value={r._id}>{r.service_role_name}</option>
                                        </React.Fragment> 
                                      ))}
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    <label>Extra Price:</label>
                                    <input type='number' className='form-control' value={tier.extra_price} onChange={ handleChangeNewTier(this, i, 'extra_price') }  />
                                  </div>
                                  <div className="col-md-6">
                                    <label>Service Type:</label>
                                    <select className='form-control' onChange={ handleChangeNewTier(this, i, 'service_type') } >
                                      <option value="PUBLIC">Public</option>
                                      <option value="PRIVATE">Private</option>
                                    </select>
                                  </div>
                                  {
                                    tier.service_type == 'PRIVATE' ?
                                      <div className="col-md-6">
                                        <label>Sevice Code:</label>
                                        <input type='text' className='form-control' value={tier.service_code} onChange={ handleChangeNewTier(this, i, 'service_code') }  />
                                      </div>
                                      : void 0
                                  }
                                  <div class="col-md-12">
                                    <label>Description</label>
                                    <Editor
                                        editorState={tier.description}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="form-control"
                                        onEditorStateChange={handleChangeNewTierWYSYG(this, i, 'description')}
                                      />
                                  </div>
                                </div>
                              </td>
                              <td className="five-padding" ><button className='btn btn-danger btn-sm' onClick={event => { removeTier(this, i) }} ><i className="ft-trash-2"></i></button></td>
                            </tr>
                          </React.Fragment>  
                          ))}
                          
                          <tr>
                            <td colSpan="1" className="five-padding">
                              
                            </td>
                            <td className="five-padding" >
                              <button className='btn btn-primary btn-sm pull-right' onClick={event => { addNewTier(this) }} ><i className="ft-plus"></i></button> 
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </small>
                  </div>
                </div>
              </div>
               {this.state.form_error.map((errors, index) => (
                <React.Fragment key={index}>
                <div className="col-md-12">
                  <span className="text-danger">{errors}</span>{" "}
                </div>
                </React.Fragment>
              ))}
            </React.Fragment>   
            } 
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-save-create-service" onClick={event => {this.saveService();}}>Save</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default CreateServiceModal;
