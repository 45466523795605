import React from 'react';
import axios from "axios";
import $ from "jquery";
import { Redirect } from 'react-router-dom';
import "./assets/scss/material-kit-react.scss?v=1.9.0";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { green, orange, blue } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import ReCAPTCHA from "react-google-recaptcha";

const API_ROOT = process.env.REACT_APP_API_LINK || 'inventorylumen.test/api';

class Login extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
        isLoggedIn : false,
        _email : '',
        _password : '',
        loading: false,
        errors: [],
        recaptcha: ""
    };

    this.handleloginUser = this.handleloginUser.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaChange = this.recaptchaChange.bind(this);
  }

  recaptchaChange(value) {
    this.setState({recaptcha: value, errors: []});

  }
  
  recaptchaExpire(value) {
    this.setState({recaptcha: ""});
  }

  componentDidMount()
  {
      document.body.classList.remove('vertical-layout');
  }
  
  handleChangeInput(event, input){
    console.log(event, input);

    this.setState({ [input]: event.target.value});
  }

  handleloginUser() {
    const email = this.state._email.value;
    const password = this.state._password.value;
    $(".btn-login").attr("disabled", "disabled").html('Logging in...');
    
    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    axios.post(API_ROOT + "/user/login", formData).then(response => { return response; })
      .then(json => {
        if (json.data.status === 1) {

          let appState = {
            isLoggedIn: true,
            user: json.data.user,
            _email: this.state._email.value,
            password: this.state._password.value
          };

          localStorage["appState"] = JSON.stringify(appState);
          
          window.location.pathname = json.data.url_redirect; 
          
        } 
        else alert("Login Failed!");
        $(".btn-login").removeAttr("disabled").html("Login");
      })
      .catch(error => {
        alert(`An Error Occured! ${error}`);
        $(".btn-login").removeAttr("disabled").html("Login");
      });

  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({loading: true, redirect: '', errors: []}); 
    axios.post(API_ROOT + "/user/login", {email: this.state._email, password: this.state._password }).then(response => { return response; })
      .then(json => {
        if (json.data.status === 1) {

          let appState = {
            isLoggedIn: true,
            user: json.data.user,
            _email: this.state._email.value,
            password: this.state._password.value
          };

          localStorage["appState"] = JSON.stringify(appState);
          
          window.location.pathname = '/'; 
          
        } 
        else {
          this.setState({errors: json.data.error});
        }

        this.setState({loading: false});
      })
      .catch(error => {
        alert(`An Error Occured! ${error}`);
        $(".btn-login").removeAttr("disabled").html("Login");
      });
  }
  Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://bluehive.com.ph/">
          Blue Hive Inc.
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }


  redirectIfLoggedIn()
  {

    const outerTheme = createMuiTheme({
      palette: {
        primary: {
          main: blue[500],
        },

      },
    });

    return (
      <div className="app-content content app-content-register">
        <div className="content-wrapper">
          <ThemeProvider theme={outerTheme}>
            <CssBaseline />
            <Card className="margin-top-50-px" >
              <CardMedia
                style={ { height: "100px", backgroundRepeat: "no-repeat", backgroundSize: "auto 100%", backgroundColor: '#1F55D0',  backgroundPosition: "right top", position: "relative"}}
                image="/Front/img/home-light-bulb-trim.png"
                title="Blue Hive Logo"
              />
              <CardContent style={{position: 'absolute', top: "13%", color: "#fff"}} className="h5-sign-in" ><h5 ><b>Already have an account?</b></h5></CardContent>
              <CardContent>
              <div >
                <center>
                  <Avatar className="bg-lb"><LockOutlinedIcon /></Avatar>
                  <Typography component="h1" variant="h5">Sign in</Typography>
                </center>
                <form  onSubmit={this.handleSubmit}>
                  <TextField value={this.state._email} onChange={value => this.handleChangeInput(value, '_email')} variant="outlined" margin="normal" type="email" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
                  <TextField value={this.state._password} onChange={value => this.handleChangeInput(value, '_password')} variant="outlined" margin="normal" equired fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                  <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me"  />
                  <div className='g-recaptcha g-recaptcha-login'><ReCAPTCHA onChange={this.recaptchaChange} onExpired={this.recaptchaExpire} sitekey="6LducicaAAAAAMd2odg3cAyrw1nqwYnB_QRhYVPE"  /></div>
                  {
                    this.state.loading ? <center><Button type="submit" disabled fullWidth variant="contained" color="primary" >Signing in..</Button></center> :<Button type="submit" disabled={this.state.recaptcha == "" ? true : false} fullWidth variant="contained" color="primary" >Sign In</Button>
                  }
                  <Grid container className="margin-top-px">
                    <Grid item>
                      <Link href="/register" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                  <br />
                  {this.state.errors.map((error, i) => (  
                      <Alert severity="error">{error}</Alert>
                  ))}

                </form>
              </div>
              </CardContent>
            </Card>  
            <Box mt={8}>
              {this.Copyright()}
            </Box>
        </ThemeProvider>    
      </div>
    </div>
    );

  }

  render() {
    return this.redirectIfLoggedIn();
  }
}

export default Login;
