import React from "react";
import { Link, Redirect } from 'react-router-dom';
import Navbar from './Navbar';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { green, orange, blue } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import countryList from 'react-select-country-list';
// import { TextareaAutosize } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector-material-ui-new';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {RegisterApi} from './register.api';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

class Register extends React.Component {

  constructor (props) {
    super(props);

    if(JSON.parse(localStorage["appState"]).user_form)
    {
      // this.state = JSON.parse(localStorage["appState"]).user_form;
      this.state = { open: false, redirect: "", loading: false, first_name: '', last_name: '', contact: '', password: '', email: '', password_confirmation: '', street_1: '', street_2: '', city: '', country: 'Philippines', region: '', region_list: [], country_value: "", recaptcha: "", errors: [] };
    }
    else
    {
      this.state = { open:false, redirect: "", loading: false, first_name: '', last_name: '', contact: '', password: '', email: '', password_confirmation: '', street_1: '', street_2: '', city: '', country: 'Philippines', region: '', region_list: [], country_value: "", recaptcha: "", errors: [] };
    }
    

    this.handleChangeCountry = this.handleChangeCountry.bind(this); 
    this.handleChangeRegion = this.handleChangeRegion.bind(this); 
    this.handleSubmit = this.handleSubmit.bind(this);
    this.recaptchaChange = this.recaptchaChange.bind(this);
    this.handleOk = this.handleOk.bind(this);


    // ["Philippines", "PH", "Abra~ABR|Agusan del Norte~AGN|Agusan del Sur~AGS|A…e~ZAN|Zamboanga del Sur~ZAS|Zamboanga Sibugay~ZSI"]
  }

  selectCountry (val) {
    this.setState({ country: val });
  }

  selectRegion (val) {
    this.setState({ region: val });
  }

  handleChangeCountry(event)
  {
    let country = event.target.value;
    console.log(event.target);
    // console.log(val, label);
    let region_list = [];

    if (country) {
      region_list = country[2].split("|").map(regionPair => {
        let [regionName, regionShortCode = null] = regionPair.split("~");
        return regionName;
      });

      this.setState({country: country[0], region_list: region_list, region: "", country_value: country});
    }
    else
    {
      this.setState({country: "", region_list: []});
    }

    
  }

  handleOk()
  {
    this.setState({errors: [], loading: false, redirect: "/services"});
  }

  recaptchaChange(value) {
    this.setState({recaptcha: value, errors: []});

  }
  
  recaptchaExpire(value) {
    this.setState({recaptcha: ""});
  }


  handleChangeRegion(event, region)
  {
    this.setState({region: event.target.value});
  }

  handleChangeInput(event, input){
    console.log(event, input);

    this.setState({ [input]: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({loading: true, redirect: ''});
    if(this.state.recaptcha)
    {
      RegisterApi.register(this.state).then(return_data => {
        if (return_data.data.status == 1) {
          
          this.setState({loading: false, errors: []});
          let appState = {
            isLoggedIn: true,
            user: return_data.data.user,
            _email: this.state.email,
            password: this.state.password,
            user_form: this.state
          };

          localStorage["appState"] = JSON.stringify(appState);
          
          this.setState({open: true});
          // this.setState({errors: [], loading: false, redirect: "/services"});
        }
        else
        {
          this.setState({ errors: return_data.data.errors, loading: false});
        }
      })
      .catch(error => {
        this.setState({loading: false});
      });
    }
    else
    {
      let errors = [];

      errors.push("ReCAPTCHA is required.");
      this.setState({ loading: false, errors: errors});
    }
    

    
  }

  render() {
    let steps = ['Create account', 'Select service', 'Subscribe', 'Complete'];
    const outerTheme = createMuiTheme({
      palette: {
        primary: {
          main: blue[500],
        },
      },
    });

    const defaultProps = {
      options: CountryRegionData,
      getOptionLabel: (option) => option.title,
    }

    const { country, region } = this.state;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    console.log(CountryRegionData);

    return (
      <React.Fragment>  
      <Navbar /> 
      <div className="app-content content app-content-register">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header ">
                  <div className="content-header-left breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Register</h3>
                  </div>

                  <Stepper activeStep={0} alternativeLabel>
                      {steps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
              </div>
              <div className="content-body">
                  <section className="row">
                    <form onSubmit={this.handleSubmit} className="col-sm-12" >
                      <input style={{display: 'none'}} type="text" name="email"/>
                      <input style={{display: 'none'}} type="password" name="password"/>

                      <div >
                          <div id="what-is" className="card card-register">
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                    <ThemeProvider theme={outerTheme}>
                                      <h4 className="mb-1 theme-text">Account Information</h4>
                                      <Grid container spacing={3}>
                                        <Grid item xs={6} className="padding-5px"><TextField variant="outlined" id="standard-basic"  autocomplete='off'  required label="First Name" name="first_name" fullWidth="true" value={this.state.first_name} onChange={value => this.handleChangeInput(value, 'first_name')}  /></Grid>
                                        <Grid item xs={6} className="padding-5px"><TextField variant="outlined" id="standard-basic" autoComplete='off'  required label="Last Name" name="last_name" fullWidth="true" value={this.state.last_name} onChange={value => this.handleChangeInput(value, 'last_name')} /></Grid>
                                        <Grid item xs={12} className="padding-5px"><TextField variant="outlined" id="standard-basic" autoComplete='off' required label="Contact" name="contact" fullWidth="true" value={this.state.contact} onChange={value => this.handleChangeInput(value, 'contact')} /></Grid>
                                        <Grid item xs={12} className="padding-5px"><TextField variant="outlined" id="standard-basic" autoComplete="none"   required type="email" name="email" label="Email" fullWidth="true" value={this.state.email} onChange={value => this.handleChangeInput(value, 'email')} /></Grid>
                                        <Grid item xs={6} className="padding-5px"><TextField variant="outlined" id="standard-basic" autoComplete='off' required type="password" name="password" label="Password" value={this.state.password} fullWidth="true" onChange={value => this.handleChangeInput(value, 'password')} /></Grid>
                                        <Grid item xs={6} className="padding-5px"><TextField variant="outlined" id="standard-basic" autoComplete='off' required type="password" name="password_confirmation" label="Confirm" value={this.state.password_confirmation} fullWidth="true" onChange={value => this.handleChangeInput(value, 'password_confirmation')} /></Grid>
                                      </Grid>
                                      <br /><br />
                                      <h4 className="mb-1 theme-text">Billing Address</h4>
                                      <Grid container spacing={3}>
                                        <Grid item xs={12} className="padding-5px"><TextField variant="outlined" id="standard-basic" required type="text" label="Street 1" value={this.state.street_1} multiline onChange={value => this.handleChangeInput(value, 'street_1')} rows={2} fullWidth="true" /></Grid>
                                        <Grid item xs={12} className="padding-5px"><TextField variant="outlined" id="standard-basic"  type="text" label="Street 2" value={this.state.street_2} multiline onChange={value => this.handleChangeInput(value, 'street_2')} rows={2} fullWidth="true" /></Grid>
                                        <Grid item xs={12} className="padding-5px"><TextField variant="outlined" id="standard-basic" required type="text" label="City" value={this.state.city} fullWidth="true" onChange={value => this.handleChangeInput(value, 'city')} /></Grid>
                                        <Grid item xs={12} sm={6} className="padding-5px">
                                          <FormControl variant="outlined" fullWidth="true">
                                            <InputLabel id="country-select">Country</InputLabel>
                                            <Select labelId="country-select" id="country-select"label="Country" required onChange={this.handleChangeCountry} value={this.state.country_value} >
                                              {CountryRegionData.map((option, i) => ( 
                                                <MenuItem value={option}>{option[0]}</MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="padding-5px">
                                          <FormControl variant="outlined" fullWidth="true">
                                            <InputLabel id="region-select">Region</InputLabel>
                                            <Select labelId="region-select" id="region-select" label="Region" required onChange={this.handleChangeRegion}  value={this.state.region} >
                                              {this.state.region_list.map((option, i) => ( 
                                                <MenuItem value={option}>{option}</MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </Grid> 
                                      </Grid>                            
                                      <div className='g-recaptcha '><ReCAPTCHA onChange={this.recaptchaChange} onExpired={this.recaptchaExpire} sitekey="6LducicaAAAAAMd2odg3cAyrw1nqwYnB_QRhYVPE"  /></div>
                                    </ThemeProvider>

                                    {this.state.errors.map((error, i) => (  
                                        <Alert severity="error">{error}</Alert>
                                    ))}
                                  </div>
                              </div>
                          </div>
                          {
                            this.state.loading ? <CircularProgress className="pull-right" /> : <button className='btn btn-primary pull-right' to="/services">Next</button> 
                          }
                          
                      </div>
                    </form>  
                  </section>
              </div>
          </div>
        </div>
        <Dialog open={this.state.open} disableBackdropClick disableEscapeKeyDown maxWidth="xs" aria-labelledby="confirmation-dialog-title">
          <DialogContent >
            <center>Account has been created</center>
          </DialogContent>
          <DialogActions>
            <center>
              <button onClick={this.handleOk} className="btn btn-primary btn-sm">
                Continue
              </button>
            </center>
          </DialogActions>
        </Dialog>
      </React.Fragment>    
    );
  }
}

export default Register;
