import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { systemAccessApi } from "./SystemAccess.service";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import PropTypes from 'prop-types';

class CreateSystemAccessModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowCreateAccessModal = this.handleShowCreateAccessModal.bind(
      this
    );
    this.handleCloseCreateAccessModal = this.handleCloseCreateAccessModal.bind(
      this
    );
    this.state = {
      form_error: [],
      show: false,
      system_access_name: "",
      system_access_description: "",
      system_access_api_link: "",
      system_access_status: 0,
      loading: false,
      errors: []
    };
  }

  static propTypes = {
    getSystemAccessList: PropTypes.func.isRequired,
  }

  handleShowCreateAccessModal() {
    this.setState({ show: true });
  }
  handleCloseCreateAccessModal() {
    this.setState({ show: false });
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  saveSystemAccessCreate = event => {
    $(".btn-save-create-systemaccess")
      .attr("disabled", "disabled")
      .html("Creating...");

    this.setState({ loading: true });
    let postParams = {
      system_access_name: this.state.system_access_name,
      system_access_description: this.state.system_access_description,
      system_access_api_link: this.state.system_access_api_link,
      system_access_status: this.state.system_access_status
    };

    systemAccessApi
      .saveSystemAccess(postParams)
      .then(ret => {
        if (ret.data.status == 1) {
          this.setState({ form_error: [], success_message: ret.data.message });

          $(".btn-save-create-systemaccess").click();

          let resetInput = {
            system_access_name: "",
            system_access_description: "",
            system_access_api_link: "",
            system_access_status: 0,
            form_error: [],
            show: false,
            loading: false
          };

          this.setState(resetInput);

          this.props.getSystemAccessList();
        } else {
          this.setState({ form_error: ret.data.errors, success_message: "", loading: false });
          this.props.getSystemAccessList();
        }
        $(".btn-save-create-systemaccess").removeAttr("disabled").html("Save");
      })
      .catch(error => {
        let errors = ["Network error, please reload the page or you have entered an invalid API"];
        this.setState({ form_error: errors, success_message: "" , loading: false});
        this.props.getSystemAccessList();
        $(".btn-save-create-systemaccess").removeAttr("disabled").html("Save");
      });
  };

  render() {
    return (
      <div>
        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleCloseCreateAccessModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create System Access</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.loading ? ( <Spinner /> ) : 
            <React.Fragment>
              <div className="col-md-12">
                <label>System Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="system_access_name"
                  value={this.state.system_access_name}
                  onChange={this.handleChange("system_access_name")}
                />
              </div>
              <div className="col-md-12">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="system_access_description"
                  value={this.state.system_access_description}
                  onChange={this.handleChange("system_access_description")}
                />
              </div>
              <div className="col-md-12">
                <label>API Link</label>
                <input
                  type="text"
                  className="form-control"
                  name="system_access_api_link"
                  value={this.state.system_access_api_link}
                  onChange={this.handleChange("system_access_api_link")}
                />
              </div>
              <div className="col-md-12">
                <label>Status</label>
                <select
                  className="form-control"
                  name="system_access_status"
                  value={this.state.system_access_status}
                  onChange={this.handleChange("system_access_status")}
                >
                  <option value="0">Active</option>
                  <option value="1">Disabled</option>
                </select>
              </div>
              {this.state.form_error.map((errors, index) => (
                <React.Fragment key={index}>
                <div className="col-md-12">
                  <span className="text-danger">{errors}</span>{" "}
                </div>
                </React.Fragment>
              ))}
            </React.Fragment>   
            } 
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-save-create-systemaccess" onClick={event => {this.saveSystemAccessCreate();}}>Save</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default CreateSystemAccessModal;
