import React from "react";
import axios from "axios";
import {serviceApi} from './Service.service';
import { role } from "./Role.service";
import { Modal, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import Spinner from "../../Includes/Spinner";
import CreateServiceModal from './Service.modal.create';
import EditServiceModal from './Service.modal.edit';
import $ from "jquery";
import Parser from 'html-react-parser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export default class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: JSON.parse(localStorage["appState"]).user.auth_token,
      users: [],
      mode: 'create',
      _id: '',
      'service_name': '',
      'service_name' : '',
      'service_price' : '',
      'service_discount': '',
      'service_show_in': 'public',
      'newTier': [
        { name: '', price: 0, role: ''}
      ],
      'roles' : [],
      'paypal_products': [],
      'first_paypal_product': '',
      'first_role' : '',
      'Services': [],
      'Services_paginate' : [],
      activePage: 1,
      search: '',
      roleSelect: [],
      '_remove' : []
    };
  }


  CreateServiceModalRef = ({handleShowCreateServiceModal}) => {
     this.showModalCreateService = handleShowCreateServiceModal;
  }

  EditServiceModalRef = ({handleShowEditServiceModal}) => {
     this.showModalEditService = handleShowEditServiceModal;
  }

  onEditModalOpenClick = (_id) => {
    this.showModalEditService(_id, this.state.roles);
   }

  onCreateModalOpenClick = () => {
    this.showModalCreateService();
   }

  getServicesList = async () => {
    this.setState({ loading: true });
    serviceApi.list({ page: this.state.activePage, search: this.state.search }).then( r => {
      console.log(r);
      if(r.data.Services)
      {
        this.setState({ Services: r.data.Services.data, Services_paginate: r.data.Services,  loading: false  });
        console.log(this.state);
      }
    }).catch(error => {
      alert("Something went wrong, please reload the page");
    });
  }

  handlePageChange = prop => event => {
    this.setState(
      {
        activePage: event,
        'success_message': ''
      },
      () => {
        this.getServicesList();
      }
    );
  };


  confirmDeleteRole = service => {
    this.setState({'_remove': service});
  };

  deleteService =  event =>{
    $(".btn-delete-service").attr("disabled", "disabled").html("Removing...");

      serviceApi
      .deleteService(this.state._remove._id)
      .then(r => {
        this.setState({_remove: [], success_message: r.data.message});
        $(".btn-close-modal-delete-service").click();
        $(".btn-delete-service").removeAttr("disabled").html("Remove");
        this.getServicesList();
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "" });
        $(".btn-delete-service").removeAttr("disabled").html("Remove");
      });

  }


  componentDidMount() {
    let users = [];

    this.getServicesList();


    role.list()
      .then(r => {
        console.log(r);
        if (r.data.status == 1) {
          this.setState({
            roles: r.data.service_role_list.data,
            first_role: r.data.service_role_list.data[0]._id,
            paypal_products: r.data.paypal_products,
            first_paypal_product: r.data.paypal_products[0]._id
          });
        }
      }).catch(error => {
        alert("Something went wrong, please reload the page");
      });


  }

  render() {
    return (
      <React.Fragment>  
        <div className="app-content content">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header row">
                  <div className="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Services</h3>
                      <div className="breadcrumbs-top d-inline-block">
                          <div className="breadcrumb-wrapper mr-1">
                              <ol className="breadcrumb">
                                  <li className="breadcrumb-item"><a href="/subscription/services">List</a></li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <div className="content-header-right col-md-4 col-12 d-block "><a className="btn btn-primary btn-min-width float-right box-shadow-4 mr-1 mb-1" data-toggle="modal" data-target="#new-service-modal" href="#" onClick={this.onCreateModalOpenClick} ><i className="ft-plus"></i> Create Services</a></div>
              </div>
              <div className="content-body">
                  <section className="row">
                      <div className="col-sm-12">
                          <div id="what-is" className="card">
                              <div className="card-header">
                                  <h4 className="card-title">Services Management</h4>
                                  <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                              </div>
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                      <div className="card-text table-responsive">
                                          <table className='table table-bordered table-hover table-striped table-condensed table-sm'>
                                            <thead>
                                              <tr>
                                                <th colSpan="20">
                                                  <div className='col-sm-3 col-md-3 float-md-right'><input type="text" className='form-control input-sm' placeholder="Search.." /></div>
                                                </th>
                                              </tr>
                                              <tr className='text-center'>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Price</th>
                                                <th>Discount</th>
                                                <th>Tier/s</th>
                                                <th>Url Code</th>
                                                <th>Public/Private</th>
                                                <th></th>
                                              </tr>
                                            </thead>   
                                            <tbody>
                                             {this.state.loading ? (
                                                <tr><td colSpan="20"><Spinner /></td></tr>
                                                            ) :
                                               <React.Fragment>
                                                {Object.keys(this.state.Services).length == 0? <tr><td colSpan="20"><center>---No data---</center></td></tr> :null }
                                                {this.state.Services.map((service, key) => (
                                                  <React.Fragment key={service._id}>
                                                   <tr>
                                                      <td>{service.service_name}</td>
                                                      <td>{service.service_show_details}</td>
                                                      <td>{service.service_price}</td>
                                                      <td>{service.service_discount}%</td>
                                                      <td>
                                                        <table className='table'>
                                                        <thead>
                                                          <tr>
                                                            <th>Name</th>
                                                            <th>Price</th>
                                                            <th>Role</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                        {service.tier.map((tier, key) => ( 
                                                          <tr key={key}>
                                                            <td>{tier.tier_name}</td>
                                                            <td>{tier.tier_price}</td>
                                                            <td>{tier.role.service_role_name}</td>
                                                          </tr>
                                                        ))}
                                                        </tbody>
                                                        </table> 
                                                      </td>
                                                      <td>N/A</td>
                                                      <td>Public</td>
                                                      <td>
                                                        <button className="btn btn-primary" onClick={event => { this.onEditModalOpenClick(service); }} data-toggle="modal" data-target="#new-service-modal">
                                                          <i className="ft-edit"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button className="btn btn-danger" onClick={event => { this.confirmDeleteRole(service); }} data-toggle="modal" data-target="#remove-service-modal">
                                                          <i className="ft-trash"></i>
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                ))}
                                                </React.Fragment>
                                              }



                                              <tr>
                                                <td colSpan="20">
                                                  <div className="d-flex justify-content-center">
                                                    <Pagination
                                                      activePage={this.state.activePage}
                                                      itemsCountPerPage={10}
                                                      totalItemsCount={this.state.Services_paginate.total}
                                                      pageRangeDisplayed={10}
                                                      onChange={this.handlePageChange()}
                                                      itemClass={"page-item"}
                                                      linkClass={"page-link"}
                                                      className={"pagination"}
                                                    />
                                                  </div>  
                                                </td>
                                              </tr>
                                            </tbody> 
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
        </div>

        <div id="remove-service-modal" className="modal" tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-dialog-centered modal-md"role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Remove Service</h5>
                <button type="button" className="close btn-close-modal-delete-service" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <center>Are you sure you want to remove "{ this.state._remove.service_name}"</center>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-delete-service"  onClick={event => { this.deleteService(); }} >Confirm</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>   

        <CreateServiceModal  ref={this.CreateServiceModalRef}   getServicesList={this.getServicesList} roles={this.state.roles} first_role={this.state.first_role} paypal_products={this.state.paypal_products} first_paypal_product={this.state.first_paypal_product} ></CreateServiceModal>
        <EditServiceModal  ref={this.EditServiceModalRef}  getServicesList={this.getServicesList} roles={this.state.roles} first_role={this.state.first_role} paypal_products={this.state.paypal_products} first_paypal_product={this.state.first_paypal_product} ></EditServiceModal>
      </React.Fragment>    
    );
  }
}