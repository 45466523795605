import React from "react";
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { green, orange, blue } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {RegisterApi} from './register.api';
import Skeleton from '@material-ui/lab/Skeleton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import renderHTML from 'react-render-html';

class Services extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      services: [],
      services_: [{_id: '0', name: 'Cloud Email', 'checked' : false}, 
        {_id: '1', name: 'Cloud Hosting', 'checked' : false},
        {_id: '2', name: 'Web SLA', 'checked' : false},
        {_id: '3', name: 'E-commerce', 'checked' : false},
        {_id: '4', name: 'Blitz Inventory', 'checked' : false},
        {_id: '5', name: 'Blitz POS', 'checked' : false},
        {_id: '6', name: 'Blitz WIFI', 'checked' : false}
      ],
      hidden_services:[],
      hidden_services_: [{_id: '0', name: 'Cloud Email', 'checked' : false, code: 'test1'}, 
        {_id: '1', name: 'Cloud Hosting', 'checked' : false, code: 'test2'},
        {_id: '2', name: 'Web SLA', 'checked' : false, code: 'test3'},
        {_id: '3', name: 'E-commerce', 'checked' : false, code: 'test4'},
        {_id: '4', name: 'Blitz Inventory', 'checked' : false, code: 'test5'},
        {_id: '5', name: 'Blitz POS', 'checked' : false, code: 'test6'},
        {_id: '6', name: 'Blitz WIFI', 'checked' : false, code: 'test7'}
      ],
      service_select_type: 0,
      service_code: "",
      selected_code_service: {},
      selected_code_error : false,
      loading: false,
      open: false,
      dialog_content: "",
      dialog_title: ""
    };

    this.handleCheck = this.handleCheck.bind(this);
    // this.handleChecktest = this.handleChecktest.bind(this);
    
  }


  componentDidMount() {
    let users = [];

    this.getServicesList();

    
  }

  getServicesList = async () => {

     this.setState({ loading: true });
    RegisterApi.serviceList({}).then( r => {
      this.setState({ loading: false });

      if(r.data.Services)
      {
        this.setState({ services: r.data.Services, hidden_services: r.data.ServicesHidden});

        let selectedService = localStorage["selectedService"];
        if(selectedService)
        {
          selectedService = JSON.parse(selectedService);
          selectedService.checked = false;
          console.log(selectedService);

          this.handleCheck(null, selectedService);
          if(selectedService.code)
          {
            this.handleChangeCode(null, selectedService.code);
          }
        }

      }
    }).catch(error => {
      console.log(error);
      localStorage["appState"] = JSON.stringify({isLoggedIn: false,user: {}});
      window.location.pathname = '/login'; 
    });
   }

   handleChecktest(event, checked_service){
    console.log(1);
    console.log(checked_service);
    this.setState({open: true, dialog_content: checked_service.description, dialog_title: checked_service.name});
    let service_select_type = this.state.service_select_type;

    if(service_select_type != 2)
    {
      console.log(service_select_type);
      this.handleCheck(null, checked_service);
    }
    
    // return false;
  }


  handleCheck(event, checked_service){
    console.log(2);
    let services = [];
    let service_select_type = 0;

    this.state.services.map((service, i)=>{

      if(checked_service.checked == true)
      {
        service.checked = false;
      }
      else
      {
        service.checked = service._id == checked_service._id ? true : false;
      }

      if(service.checked == true)
      {
        service_select_type = 1;

        localStorage["selectedService"] = JSON.stringify(service);
      }

      services.push(service);
    });

    this.setState({services: services, service_select_type: service_select_type, service_code : '', selected_code_service : {}, selected_code_error: false});
  }

  handleChangeCode(event, code)
  {
    let services = [];
    let service_select_type = 0;
    let selected_code_service = {};
    let selected_code_error = true;
    let value = "";
    if(code)
    {
      value = code;
    }
    else
    {
      value = event.target.value;
    }

    this.state.services.map((service, i)=>{

      service.checked = false;

      services.push(service);
    });
    console.log(this.state.hidden_services);
    this.state.hidden_services.map((service, i)=>{

      if(service.code == value)
      {
        service_select_type = 2;
        selected_code_service = service;
        localStorage["selectedService"] = JSON.stringify(selected_code_service);
        console.log('selected service', selected_code_service);
        selected_code_error = false;
      }
    });

    if(value === "")
    {
      selected_code_error = false;
    }


    this.setState({services: services, service_select_type: service_select_type, service_code: value, selected_code_service : selected_code_service, selected_code_error: selected_code_error});
  }

  render() {
    let steps = ['Create account', 'Select service', 'Subscribe', 'Complete'];
    const outerTheme = createMuiTheme({
      palette: {
        primary: {
          main: blue[500],
        },
      },
    });

    const handleClickOpen = () => {
      this.setState({open: true});
    };

    const handleClose = () => {
      console.log(false);
      this.setState({open: false});
    };


    return (
      <React.Fragment>  
      <Navbar /> 
      <div className="app-content content app-content-register">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header ">
                  <div className="content-header-left breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Services</h3>
                  </div>
                  <Stepper activeStep={1} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
              </div>
              <div className="content-body">
                  <section className="row">
                      <div className="col-sm-12">
                          <div id="what-is" className={this.state.service_select_type === 1 ? 'card card-register-20 card-active' : 'card card-register-20'}>
                              
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                    <i class="feather icon-check pull-right hide icon-check-top"></i>
                                    <h4>Option 1: Select Service</h4>

                                    {this.state.loading ?
                                      <React.Fragment><Skeleton /><Skeleton /><Skeleton /><Skeleton /></React.Fragment> : void 0
                                    }
                                    <ThemeProvider theme={outerTheme}>
                                      <FormGroup>
                                        {this.state.services.map((service, i) => (  
                                            <FormControlLabel
                                            control={<Checkbox  name="gilad" color="primary" checked={service.checked} onChange={value => this.handleCheck(value, service)}  />}
                                            label={ <span>{service.name} <HelpOutlineIcon  onClick={value => this.handleChecktest(false, service)} /></span>}
                                          />

                                        ))}

                                      </FormGroup>
                                    </ThemeProvider>
                                  </div>
                              </div>
                          </div>
                          <div id="what-is" className={this.state.service_select_type === 2 ? 'card card-register card-active' : 'card card-register'}>
                              
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                    <i class="feather icon-check pull-right hide icon-check-top"></i>
                                    <h4>Option 2: Service Code</h4>
                                    <center >
                                      <div className="input-code">
                                        <ThemeProvider theme={outerTheme}>
                                          <TextField id="standard-basic" variant="outlined" error={this.state.selected_code_error} helperText={this.state.selected_code_error === true ? 'Service Not Found' : ' '} value={this.state.service_code} onChange={value => this.handleChangeCode(value)} label="Input Code Here" fullWidth="true" color="primary" />
                                        </ThemeProvider>
                                      </div>
                                    </center>
                                    <br />
                                    <div className="selected-service-code">{ this.state.selected_code_service.name} { this.state.selected_code_service.name ? <HelpOutlineIcon  onClick={value => this.handleChecktest(true, this.state.selected_code_service)} /> : void 0} </div>

                                  </div>
                              </div>
                          </div>

                          <Link to="/payment" className={this.state.service_select_type == 0 ? 'btn btn-primary pull-right disabled-link' : "btn btn-primary pull-right"} >Next</Link>
                          <Link className='btn btn-light pull-right c-white m-r-10' to="/profile">Previous</Link>
                      </div>
                  </section>
              </div>
          </div>
        </div>
        <Dialog open={this.state.open}  keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" >
            <DialogTitle id="alert-dialog-slide-title">{this.state.dialog_title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                { renderHTML(this.state.dialog_content) }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
      </React.Fragment>    
    );
  }
}

export default Services;
