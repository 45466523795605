import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { systemAccessApi } from "./SystemAccess.service";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";

class EditSystemAccessModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowEditAccessModal = this.handleShowEditAccessModal.bind(this);
    this.handleCloseEditAccessModal = this.handleCloseEditAccessModal.bind(this);
    this.state = {
      form_error: [],
      show: false,
      system_access: [],
      system_access_name: "",
      system_access_description: "",
      system_access_api_link: "",
      system_access_status: 0,
      loading: false,
      errors: []
    };
  }
  handleShowEditAccessModal(_id) {
    this.setState({ show: true,  loading: true});
    systemAccessApi.getSystemAccess(_id).then(ret => { this.setState({ system_access: ret.data.SystemAccess, loading: false}); });
  }
  handleCloseEditAccessModal() {
    this.setState({ show: false });
  }

  handleChange = prop => event => {

    let system_access = this.state.system_access;
    system_access[prop] = event.target.value;

    this.setState({ system_access: system_access });
  };

  saveSystemAccessUpdate = event => {
    $(".btn-save-edit-systemaccess")
      .attr("disabled", "disabled")
      .html("Creating...");

    this.setState({ loading: true });
    systemAccessApi
      .saveSystemAccessUpdate(this.state.system_access, this.state.system_access._id)
      .then(ret => {

        console.log(ret);
        if (ret.data.status == 1) {
          this.setState({ form_error: [], success_message: ret.data.message });

          $(".btn-save-edit-systemaccess").click();

          let resetInput = {
            system_access_name: "",
            system_access_description: "",
            system_access_api_link: "",
            system_access_status: "",
            form_error: [],
            show: false,
            loading: false
          };

          this.setState(resetInput);
        } else {
          this.setState({ form_error: ret.data.errors, success_message: "", loading: false });
        }
        
        $(".btn-save-edit-systemaccess").removeAttr("disabled").html("Save");

        this.props.getSystemAccessList();  
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "" , loading: false});
      });
  };

  render() {
    return (
      <div>
        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleCloseEditAccessModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit System Access</Modal.Title>
          </Modal.Header>
          
          {this.state.loading ? ( <Spinner />  ) : 
            <React.Fragment>
              <Modal.Body>  
              <div className="col-md-12">
                <label>System Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="system_access_name"
                  value={this.state.system_access.system_access_name}
                  onChange={this.handleChange("system_access_name")}
                />
              </div>
              <div className="col-md-12">
                <label>Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="system_access_description"
                  value={this.state.system_access.system_access_description}
                  onChange={this.handleChange("system_access_description")}
                />
              </div>
              <div className="col-md-12">
                <label>API Link</label>
                <input
                  type="text"
                  className="form-control"
                  name="system_access_api_link"
                  value={this.state.system_access.system_access_api_link}
                  onChange={this.handleChange("system_access_api_link")}
                />
              </div>
              <div className="col-md-12">
                <label>Status</label>
                <select
                  className="form-control"
                  name="system_access_status"
                  value={this.state.system_access.system_access_status}
                  onChange={this.handleChange("system_access_status")}
                >
                  <option value="0">Active</option>
                  <option value="1">Disabled</option>
                </select>
              </div>
              {this.state.form_error.map(errors => (
                <div className="col-md-12">
                  <span className="text-danger">{errors}</span>{" "}
                </div>
              ))}
              
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary btn-save-edit-systemaccess" onClick={event => {this.saveSystemAccessUpdate();}}>Save</button>
            </Modal.Footer> 

            </React.Fragment>
          } 
          
        </Modal>
      </div>
    );
  }
}
export default EditSystemAccessModal;
