import React from "react";
import { Link, Redirect } from 'react-router-dom';
import Navbar from './Navbar';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { green, orange, blue } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {RegisterApi} from './register.api';
import CircularProgress from '@material-ui/core/CircularProgress';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

class Payment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected_service: {_id: '0', name: 'Cloud Email', 'checked' : false, code: 'test1', amount: 99.00, or: 150},
      quantity: 1,
      redirect: "",
      amount: 0,
      extra: 0,
      enable_extra: false,
      total_amount: 0,
      base: {},
      loading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    
    // console.log(this.this.props);
    if(localStorage["selectedService"])
    {
      let selected_service = JSON.parse(localStorage["selectedService"]);
      if(selected_service)
      {

        let base = selected_service.base;
        let qty = this.state.quantity;
        let total = (base.base_amount + base.extra_amount) * qty;

        this.setState({selected_service: selected_service, amount: base.base_amount, extra: base.extra_amount, total_amount: total, enable_extra: false, base: base});
      }
      else
      {
        this.setState({redirect: "/services"});
      }
    }
    else
    {
      this.setState({redirect: "/services"});
    }
  }

  handleCheck(event, checked_service){
    console.log(event.target.checked);

    if(event.target.checked == true)
    {
      let base = this.state.selected_service.extra;
      let qty = this.state.quantity;
      let total = (base.base_amount + base.extra_amount) * qty;

      this.setState({amount: base.base_amount, extra: base.extra_amount, total_amount: total, enable_extra: event.target.checked, base : base});

    }
    else
    {
      let base = this.state.selected_service.base;
      let qty = this.state.quantity;
      let total = (base.base_amount + base.extra_amount) * qty;

      this.setState({amount: base.base_amount, extra: base.extra_amount, total_amount: total, enable_extra: event.target.checked, base : base});
    }
  }


  handleSubmit(event) {
    console.log(event);
    this.setState({loading: true});

    RegisterApi.paypalCheckout({plan_id: this.state.base._id, quantity: this.state.quantity}).then( r => {
      // this.setState({loading: false});
      if(r.data.link)
      {
        window.location.replace(r.data.link);
        // this.setState({redirect: r.data.link});
      }
    });

    

  }

  handleChangeInput(event, input){
    console.log(event, input);
    let base = this.state.base;
    let qty = event.target.value;
    let total = (base.base_amount + base.extra_amount) * qty;

    this.setState({ [input]: event.target.value, total_amount: total});
  }

  render() {

    let steps = ['Create account', 'Select service', 'Subscribe', 'Complete'];

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const outerTheme = createMuiTheme({
      palette: {
        primary: {
          main: blue[500],
        },
      },
    });

    return (
      <React.Fragment>  
      <Navbar /> 
      <div className="app-content content app-content-register">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header ">
                  <div className="content-header-left breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Payment</h3>
                  </div>
                  <Stepper activeStep={2} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
              </div>
              <div className="content-body">
                  <section className="row">
                      <div className="col-sm-12">
                          <div id="what-is" className="card card-register">
                              
                              <div className="card-content collapse show">
                                  <div class='card-header'>
                                    <div className="table-payment">
                                      <h4><div className="h4-div">{this.state.selected_service.name}</div>
                                       <div className="pull-right input-qty">
                                        <ThemeProvider theme={outerTheme}>
                                          <TextField id="outlined-number" label="Quantity" onChange={value => this.handleChangeInput(value, 'quantity')}  value={this.state.quantity} type="number" InputLabelProps={{shrink: true,}} variant="outlined" InputProps={{ inputProps: { min: 1, max: 1000 }}} />
                                        </ThemeProvider>
                                        </div>
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                     

                                    <table className="register-table table-payment">
                                      <tr>
                                        <td>Subscription:</td>
                                        <td class='text-right'>Billed Mothly</td>
                                      </tr>
                                      <tr>
                                        <td>Amount:</td>
                                        <td  class='text-right'>PHP {this.state.amount * this.state.quantity}</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <ThemeProvider theme={outerTheme}>
                                            <FormControlLabel control={<Checkbox  onChange={value => this.handleCheck(value)} name="gilad" color="primary"   />}  label="Extra Fee" />
                                          </ThemeProvider>
                                          </td>
                                          <td  class='text-right'>{this.state.enable_extra == true? <span>+ PHP {this.state.extra * this.state.quantity}</span> : void 0}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan="2">
                                          <hr />
                                        </td>
                                      </tr>
                                      <tr className="font-weight-bold">
                                        <td>Due today:</td>
                                        <td  class='text-right'>PHP {this.state.total_amount}</td>
                                      </tr>

                                      <tr>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td>Payment Method:</td>
                                        <td  class='text-right'>PayPal Credit Card</td>
                                      </tr>
                                    </table>
                                  </div>
                              </div>
                          </div>

                          { this.state.loading == false ? <div><button className='btn btn-primary pull-right' onClick={this.handleSubmit}>Next</button><Link className='btn btn-light pull-right c-white m-r-10' to="/services">Previous</Link></div> : <CircularProgress className="pull-right" /> }

                      </div>
                  </section>
              </div>
          </div>
        </div>
      </React.Fragment>    
    );
  }
}

export default Payment;
