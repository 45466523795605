import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import PropTypes from 'prop-types';
import {roleApi} from './Role.service';
import CheckboxTree from "react-checkbox-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCheckSquare, faSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile } from "@fortawesome/free-solid-svg-icons";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-checkbox-tree/src/less/react-checkbox-tree.less";
import "react-checkbox-tree/src/scss/react-checkbox-tree.scss";

class CreateRoleModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this );
    this.state = {
      form_error: [],
      show: false,
      loading: false,
      role_name: '',
      role_description: '',
      errors: [],
      nodes : [],
      checked : [],
      expanded : [],
      default_expanded: [],
      role_same_level_view: 1
    };
  }

  static propTypes = {
    getRoleList: PropTypes.func.isRequired,
  }

  openModal() { 
    this.setState({ show: true, loading: true});
    roleApi.getAccess().then(r => {
      if (r.data.nodes) {
        let nodes = r.data.nodes;
        let expanded = r.data.expanded;
        this.setState({ nodes: nodes, expanded: expanded, default_nodes: nodes, default_expanded: expanded, loading:false}, () => { console.log(this.state)});
      }
    }).catch(error => {
      alert("Something went wrong, please reload the page");
      this.setState({ loading: false });
    });
  }
  closeModal() { this.setState({ show: false }); }
  handleChange = prop => event => { this.setState({ [prop]: event.target.value }); };

  saveRole()
  {
    $(".btn-save-create-role").attr("disabled", "disabled").html("Saving Role...");
    this.setState({ loading: true});
    roleApi
      .saveRole({role_name: this.state.role_name, role_description: this.state.role_description, role_access: this.state.checked, role_same_level_view: this.state.role_same_level_view })
      .then(r => {
        if (r.data.status == 1) 
        {
          this.setState({form_error: [], success_message: r.data.message, role_name: '', role_description: '', checked: [],  show: false});
        } 
        else 
        {
          this.setState({ form_error: r.data.errors, success_message: "", loading: false });
        }

        $(".btn-save-create-role").removeAttr("disabled").html("Save");

        this.props.getRoleList();
        console.log('getRoleList');
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "", loading: false });
      });

  }

  render() {
    return (
      <div>
        <Modal size="sm" show={this.state.show} onHide={this.closeModal} >
          <Modal.Header closeButton> <Modal.Title>Create Role</Modal.Title> </Modal.Header>
          {this.state.loading ? ( <Spinner /> ) : 
            <React.Fragment>
            <Modal.Body>
            
              
                <div className='row'>
                  <div className="col-md-12">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="role_name"
                        value={this.state.role_name}
                        onChange={this.handleChange("role_name")}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Description</label>
                      <input
                        type="text"
                        className="form-control"
                        name="role_description"
                        value={this.state.role_description}
                        onChange={this.handleChange("role_description")}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Can view same role level</label>
                      <select className='form-control' name='role_same_level_view' onChange={this.handleChange("role_same_level_view")}>
                        <option value='1'>Yes</option>
                        <option value='0'>No</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <hr />
                      <label>Access </label>

                      <CheckboxTree
                          icons={{ check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} />, uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />, halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />, expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />, expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />, expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />, collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />, parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />, parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />, leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} /> }}
                          nodes={this.state.nodes}
                          checked={this.state.checked}
                          expanded={this.state.expanded}
                          onCheck={checked => this.setState({ checked })}
                          onExpand={expanded => this.setState({ expanded })}
                      />
                    </div>
                </div>
                 {this.state.form_error.map((errors, index) => (
                  <React.Fragment key={index}>
                  <div className="col-md-12">
                    <span className="text-danger">{errors}</span>{" "}
                  </div>
                  </React.Fragment>
                ))}
                
              
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary btn-save-create-role" onClick={event => {this.saveRole();}}>Save</button>
            </Modal.Footer>
          </React.Fragment> 
          } 
        </Modal>
      </div>
    );
  }
}
export default CreateRoleModal;
