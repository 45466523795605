import React, {Component} from "react";
import axios from "axios";
import CreateRoleModal from './Role.modal.create';
import EditRoleModal from './Role.modal.edit';
import DeleteRoleModal from './Role.modal.delete';
import {roleApi} from './Role.service';
import Pagination from "react-js-pagination";
import Spinner from "../../Includes/Spinner";
import $ from "jquery";

import ReactDOM, {render} from "react-dom";
import {sortableContainer, sortableElement, DragLayer, arrayMove}  from './react-sortable-multiple-hoc';

const dragLayer = new DragLayer()

const SortableItem = sortableElement(props => {
  return (
    <div
      onClick={props.onSelect}
      className={props.className}
      style={{
        padding: '.35em'
      }}
    >
      <span
        style={{
          display: 'inline-block',
          marginRight: '10px'
        }}
      >
        {props.item.ind}
      </span>
      {props.item.val}
    </div>
  )
})

const SortableListItems = sortableContainer(({ items }) => (
  <div>
    {items.map((value, index) => (
      <SortableItem key={index} index={index} item={value} />
    ))}
  </div>
))

/**  This is the Chapter Part/Section Title, it drags as a group of items  */
const SortablePart = sortableElement(props => {
  // console.log('SortablePart - props', props)
  console.log(props.item.items);
  return (
    <div
      className="sortable-part1"
      style={{
        minWidth: '160px',
        minHeight: '270px',
        background: '#f2f2f2',
        margin: '10px',
        padding: '5px'
      }}
    >
      <div>
        <span
          style={{ background: '#cc8', display: 'block', padding: '.35em' }}
        >
          {props.item.name}
        </span>
      </div>

      <SortableListItems
        {...props}
        items={props.item.items}
        dragLayer={dragLayer}
        distance={3}
        helperClass={'selected'}
        isMultiple={true}
        helperCollision={{ top: 0, bottom: 0 }}
      />
    </div>
  )
})

/** Main Container on page */
const SortableListParts = sortableContainer(({ items, onSortItemsEnd }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      // background: 'lightblue',
      color: '#000',
      padding: '2em'
      // height: '800px',
      // overflow: 'auto',
    }}
  >

    {items.map((value, index) => (
      <SortablePart
        key={index}
        index={index}
        item={value}
        id={index}
        onMultipleSortEnd={onSortItemsEnd}
      />
    ))}
  </div>
))


export default class RoleLevel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      parts: [],
      loading: true
    }
  }

  componentDidMount() {
    const parts = []

    roleApi.getAllRole().then( r => {
      if(r.data.roles)
      {

        for (let i = 1; i <= r.data.role_count; i++) {
          const roles = []
          let roles_data = r.data.roles;


          roles_data.map((role, index) => {

            if(role.admin_role_level == i)
            {
              roles.push({ val: role.admin_role, id: role._id});
            }
          });

          parts.push({
            name: 'Rank: ' + (i),
            rank: i,
            items: roles
          })
        }
        this.setState({parts: parts, loading: false});
          console.log(parts);
      }
    }).catch(error => {
      console.log(error);
      alert("Something went wrong, please reload the page");
    });

  }

  saveRoleLevel = event => {
    $(".btn-save-rolelevel").attr("disabled", "disabled").html("Updating...");

    console.log(this.state.parts);

    this.setState({ loading: true});
    roleApi.saveRank({ranks: this.state.parts})
      .then(r => {

        this.setState({ loading: false});
        $(".btn-save-rolelevel").removeAttr("disabled").html("Save");
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "", loading: false });
      });


  };


  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      parts: arrayMove(this.state.parts, oldIndex, newIndex)
    }, () => {

      let parts = this.state.parts;
      let new_parts = [];

      console.log(parts);


      parts.map((value, index) => {
        value.name = 'Rank: ' + (index + 1);
        value.rank = (index + 1);
          new_parts.push(value);
      });

      this.setState({parts: new_parts});
    });
  }
  onSortItemsEnd = ({ newListIndex, newIndex, items }) => {
    const parts = this.state.parts.slice()
    const itemsValue = []

    items.forEach(item => {
      itemsValue.push(parts[item.listId].items[item.id])
    })
    for (let i = items.length - 1; i >= 0; i--) {
      const item = items[i]

      parts[item.listId].items.splice(item.id, 1)
    }
    parts[newListIndex].items.splice(newIndex, 0, ...itemsValue)
    this.setState({
      parts: parts
    })
  }


  render() {  

     const parts = this.state.parts.map((value, index) => {
      return {
        name: value.name,
        items: value.items.map((val, ind) => {
          return {
            val: val.val,
            id:  val.id,
            ind: ''
          }
        })
      }
    })

    return (
      <React.Fragment>  
        <div className="app-content content">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header row">
                  <div className="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Admin</h3>
                      <div className="breadcrumbs-top d-inline-block">
                          <div className="breadcrumb-wrapper mr-1">
                              <ol className="breadcrumb">
                                  <li className="breadcrumb-item"><a href="/admin/role">Roles</a>
                                  </li>
                              </ol>
                          </div>
                      </div>
                  </div>
              </div>
          
          <div className="content-body">
              <section className="row">
                <div className="col-sm-12">
                  <div id="what-is" className="card">
                    <div className="card-header">
                      <h4 className="card-title">Admin Role Level Management</h4>
                      <a className="heading-elements-toggle">
                        <i className="la la-ellipsis-v font-medium-3"></i>
                      </a>
                    </div>
                    <div className="card-content collapse show">
                      <div className="card-body">
                        <div className="card-text table-responsive">
                          <div className=''>
                          {this.state.loading ? ( <Spinner /> ): 
                            <div>
                              <SortableListParts
                                items={parts}
                                onSortEnd={this.onSortEnd}
                                onSortItemsEnd={this.onSortItemsEnd}
                                helperClass={'selected'}
                              />
                              <button className="btn btn-primary btn-save-rolelevel pull-right" onClick={event => {this.saveRoleLevel();}}>Save</button>
                            </div>
                            
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            </div>
        </div>
      </React.Fragment>    
    );
  }
}

