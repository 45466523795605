import React from "react";
import axios from "axios";
import CreateRoleModal from './Role.modal.create';
import EditRoleModal from './Role.modal.edit';
import ViewRoleModal from './Role.modal.view';
import DeleteRoleModal from './Role.modal.delete';
import {roleApi} from './Role.service';
import Pagination from "react-js-pagination";
import Spinner from "../../Includes/Spinner";

export default class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      activePage: 1,
      search: '',
      loading: false,
      role: [],
      role_paginate: []
    };
  }

  CreateServiceModalRef = ({openModal}) => { this.openModalCreateRole = openModal; }
  DeleteRoleModalRef = ({openModalDelete}) => { this.openModalDeleteRole = openModalDelete; }
  EditRoleModalRef = ({openModalEdit}) => { this.openModalEditRole = openModalEdit; }
  ViewRoleModalRef = ({openModalView}) => { this.openModalViewRole = openModalView; }

  openModalDeleteRole_ = (role) => { this.openModalDeleteRole(role); }
  OpenModalEditRole_ = (_id) => { this.openModalEditRole(_id); }
  OpenModalViewRole_ = (_id) => { this.openModalViewRole(_id); }
  OpenModalCreateRole_ = () => { this.openModalCreateRole(); }


   getRoleList = async () => {
    this.setState({ loading: true });
    roleApi.list({ page: this.state.activePage, search: this.state.search }).then( r => {
      console.log(r);
      if(r.data.roles)
      {
        this.setState({ role: r.data.roles.data, role_paginate: r.data.roles,  loading: false  });
      }
    }).catch(error => {
      alert("Something went wrong, please reload the page");
    });
  }

  handlePageChange = prop => event => {
    this.setState(
      {
        activePage: event,
        'success_message': ''
      },
      () => {
        this.getRoleList();
      }
    );
  };

  handleSearch = prop => event => {
    console.log(event.target.value);
    this.setState(
      {
        search: event.target.value,
        activePage: 1,
        'success_message': ''
      },
      () => {
        this.getRoleList();
      }
    );
  };

  componentDidMount() {

    this.getRoleList();

  }

  render() {
    return (
      <React.Fragment>  
        <div className="app-content content">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header row">
                  <div className="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Admin</h3>
                      <div className="breadcrumbs-top d-inline-block">
                          <div className="breadcrumb-wrapper mr-1">
                              <ol className="breadcrumb">
                                  <li className="breadcrumb-item"><a href="/admin/role">Roles</a>
                                  </li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <div className="content-header-right col-md-4 col-12 d-block "><a className="btn btn-primary btn-min-width float-right box-shadow-4 mr-1 mb-1" data-toggle="modal" data-target="#new-service-modal" href="#" onClick={this.OpenModalCreateRole_} ><i className="ft-plus"></i> Create Role</a></div>
              </div>
          
          <div className="content-body">
              <section className="row">
                <div className="col-sm-12">
                  <div id="what-is" className="card">
                    <div className="card-header">
                      <h4 className="card-title">Admin Role Management</h4>
                      <a className="heading-elements-toggle">
                        <i className="la la-ellipsis-v font-medium-3"></i>
                      </a>
                    </div>
                    <div className="card-content collapse show">
                      <div className="card-body">
                        <div className="card-text table-responsive">
                          <table className="table table-bordered table-hover table-striped table-condensed table-sm">
                            <thead>
                              <tr>
                                <th colSpan="20">
                                  <span className="text-success">
                                    {this.state.success_message}
                                  </span>
                                  <div className="col-sm-3 col-md-3 float-md-right">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder="Search.."
                                      onChange={this.handleSearch()}
                                    />
                                  </div>
                                </th>
                              </tr>
                              <tr className="text-center">
                                <th>Name</th>
                                <th>Description</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className={ this.state.loading ? '' : 'd-none'} >
                              <tr  >
                                <td colSpan="20">
                                  <Spinner />
                                </td>
                              </tr>
                            </tbody>
                            <tbody className={ this.state.loading ? 'd-none' : ''}>
                              {Object.keys(this.state.role).length == 0? <tr><td colSpan="20"><center>---No data---</center></td></tr> :null }
                              {this.state.role.map(role => (
                                <React.Fragment key={role._id}>
                                <tr>
                                  <td>{role.admin_role}</td>
                                  <td>{role.admin_role_description}</td>
                                  <td>
                                    
                                      <button className="btn btn-primary" onClick={event => { this.OpenModalViewRole_(role._id); }} data-toggle="modal" data-target="#new-service-modal">
                                        <i className="ft-eye"></i>
                                      </button>&nbsp;

                                    { (role.admin_role_lock_edit != 1) &&
                                      <React.Fragment>
                                        <button className="btn btn-primary" onClick={event => { this.OpenModalEditRole_(role._id); }} data-toggle="modal" data-target="#new-service-modal">
                                          <i className="ft-edit"></i>
                                        </button>
                                        &nbsp;
                                        <button className="btn btn-danger" onClick={event => { this.openModalDeleteRole_(role); }} data-toggle="modal" data-target="#remove-service-role-modal">
                                          <i className="ft-trash"></i>
                                        </button>
                                      </React.Fragment>
                                    }

                                  </td>
                                    
                                </tr>
                                </React.Fragment>
                              ))}
                              <tr>
                                <td colSpan="20">
                                  <div className="d-flex justify-content-center">
                                    <Pagination
                                      activePage={this.state.activePage}
                                      itemsCountPerPage={10}
                                      totalItemsCount={this.state.role_paginate.total}
                                      pageRangeDisplayed={10}
                                      onChange={this.handlePageChange("role_name")}
                                      itemClass={"page-item"}
                                      linkClass={"page-link"}
                                      className={"pagination"}
                                    />
                                  </div>  
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            </div>
        </div>

        <CreateRoleModal  ref={this.CreateServiceModalRef} getRoleList={this.getRoleList} ></CreateRoleModal>
        <EditRoleModal  ref={this.EditRoleModalRef} getRoleList={this.getRoleList} ></EditRoleModal>
        <DeleteRoleModal  ref={this.DeleteRoleModalRef} getRoleList={this.getRoleList} ></DeleteRoleModal>
        <ViewRoleModal  ref={this.ViewRoleModalRef} getRoleList={this.getRoleList} ></ViewRoleModal>
      </React.Fragment>    
    );
  }
}