import React, { Component } from 'react';
import { Redirect, useLocation, withRouter  } from 'react-router-dom';
import { removeUserSession } from '../Utils/Common';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class NavBar extends Component {

  constructor (props) {
    super(props);
    this.state = {redirect: '', loggedIn: false, openNav: false, redirect: ''}
    this.logout = this.logout.bind(this); 

    if(localStorage["appState"])
    {
      if(JSON.parse(localStorage["appState"]).user){
        let user = JSON.parse(localStorage["appState"]).user;
        
        if(user._id)
        {
          this.state = {redirect: '', loggedIn: true}
        }
      }
    }



  }

  logout() {
    let appState = { isLoggedIn: false, user: {} };
    removeUserSession();
    localStorage["appState"] = JSON.stringify(appState);

    this.setState({redirect: '/login'});
  }

  render() {

    const toggleDrawer = (anchor, open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      this.setState({ ...this.state, openNav: open });
    };

    const redirect = (link) => (event) => {
      this.setState({'redirect' : link});
    };


    if (this.state.redirect) {

      if(this.props.location.pathname !== this.state.redirect)
      {
        return <Redirect to={this.state.redirect} />
      }
    }

        return (
          <React.Fragment>  
            <header className="header_area headroom">
              <div className="container-fluid" style={{ width: "100% !important:"}}>
                  <div className="row align-items-center">
                      <div className="col-12" style={{ "padding-left": "0px !important:", "padding-right": "0px !important:"}} >
                          <div className="menu_area">
                              <nav className="navbar navbar-expand-lg navbar-light">
                                  <div className="box-outer">
                                    <div className="main_box">
                                      <h4 className="mt-2 sm-white text-white"><a href="/"><img src="/Front/img/bluehive_white1.svg" alt="logo" className="brand-logo" /><b className="ml-2"></b></a></h4>
                                    </div>
                                  </div>
                                  <ul className="navbar-nav ml-auto cl-effect-5" id="nav">
                                      <li className="nav-item"><button onClick={toggleDrawer("right", true)}  className="navbar-toggler-2" type="button"><img src="/Front/img/mobile-white-menu.svg" /></button></li>
                                  </ul>

                                  <SwipeableDrawer className="material-ui-nav-drawer" anchor={"right"} open={this.state.openNav} onClose={toggleDrawer("right", false)} onOpen={toggleDrawer("right", true)}  >
                                    <div >
                                      <IconButton className="icon-right-drawer" onClick={toggleDrawer("right", false)}>
                                        <ChevronRightIcon />
                                      </IconButton>
                                    </div>
                                    <Divider />
                                    {this.state.loggedIn ?
                                    <List style={{minWidth: "150px"}}>
                                      <ListItem button onClick={redirect('/subscriptions')}>
                                        <ListItemText primary="Subscriptions" />
                                      </ListItem>
                                      <ListItem button onClick={redirect('/services')}>
                                        <ListItemText primary="Services" />
                                      </ListItem>
                                      <ListItem button onClick={redirect('/profile')}>
                                        <ListItemText primary="Profile" />
                                      </ListItem>
                                      <ListItem button href="#" onClick={this.logout} >
                                        <ListItemText primary="Logout" />
                                      </ListItem>
                                    </List>
                                    :
                                    <List style={{minWidth: "150px"}}>
                                      <ListItem button onClick={redirect('/login')}>
                                        
                                        <ListItemText primary="Login"   />
                                      </ListItem>
                                      <ListItem button onClick={redirect('/register')}>
                                        
                                        <ListItemText primary="Register" />
                                      </ListItem>
                                    </List>
                                    }
                                  </SwipeableDrawer>
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </header> 
          </React.Fragment>            
        );
    
  }
}

export default withRouter(NavBar);
