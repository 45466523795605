import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import PropTypes from 'prop-types';
import {accountsAPI} from './Account.service';
import Select from 'react-select';

class DeleteUserModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowDeleteUserModal = this.handleShowDeleteUserModal.bind(
      this
    );
    this.handleCloseCreateUserModal = this.handleCloseCreateUserModal.bind(
      this
    );
    this.state = {
      show: false, loading: false,
      roles : [], form_error: [], roles: [], errors: [], roleOptions: [], default_role:[],
      _id: '', role_id: '','name' : '','birthday' : '','contact_number' : '','email' : '','password' : '',
    };
  }

  static propTypes = { getUserList: PropTypes.func.isRequired, }

  componentWillReceiveProps(newProps) { }
  handleShowDeleteUserModal(user) { 

    let default_role = { value: user.admin_role_id, label: user.role.admin_role, index: 0 };
    this.setState({ show: true,
      _id: user._id, 
      role_id: user.admin_role_id,
      'name' : user.admin_name,
      'birthday' : user.subscriber_birthday,
      'contact_number' : user.contact_number,
      'email' : user.admin_email,
      'password' : '',
      default_role: default_role
     }); 
    this.getRoles(); 
  }
  handleCloseCreateUserModal() { this.setState({ show: false }); }
  handleChange = prop => event => { this.setState({ [prop]: event.target.value }); };
  handleSelectRole(value) { this.setState({ role_id: value.value}) }

  getRoles = async () => {
    this.setState({ loading: true, form_error: [] });
    accountsAPI.getRoles().then( r => {
      if(r.data.roles)
      {
        let roles = r.data.roles; let roleOptions = [];

        roles.map((role, i) => { roleOptions[i] = { value: role._id, label: role.admin_role, index: i}; });

        this.setState({roleOptions: roleOptions, roles: roles, loading:false});

      }
    }).catch(error => { let form_error = ["Network error, please reload the page."]; this.setState({ loading: false, form_error: form_error });  });
  }



  saveUser = event => {
    $(".btn-save-user").attr("disabled", "disabled").html("Deleting...");
    console.log('saveUser');
    this.setState({ loading: true });

      let postParams = {
        birthday : this.state.birthday,
        name : this.state.name,
        contact_number : this.state.contact_number,
        username : this.state.email,
        password : this.state.password,
        role_id : this.state.role_id,
      }

      accountsAPI.deleteUser(this.state._id)
      .then(ret => {
        if (ret.data.status == 1) 
        {
          this.setState({ birthday : '', name : '', contact_number : '', username : '', password : '', role_id : '', loading:false, show: false, form_error: []});
          this.props.getUserList();
        } 
        else 
        {
          this.setState({ form_error: ret.data.errors, success_message: "", loading: false });
        }
        $(".btn-save-user").removeAttr("disabled").html("Confirm");
      })
      .catch(error => {
        let errors = ["Network error, please reload the page or you have entered an invalid API"];
        this.setState({ form_error: errors, success_message: "" , loading: false});
        $(".btn-save-user").removeAttr("disabled").html("Confirm");
      });
  };



  render() {
    return (
      <div>
        <Modal
          size="sm"
          show={this.state.show}
          onHide={this.handleCloseCreateUserModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          {this.state.loading ? ( <Spinner /> ) : 
            <React.Fragment>
              <Modal.Body>
                <React.Fragment>
                  <div className='row'>
                    <div className='col-md-12'>
                      Are you sure you want to remove "{this.state.name}"? 
                    </div>
                  </div>
                  
                   {this.state.form_error.map((errors, index) => (
                    <React.Fragment key={index}>
                    <div className="col-md-12">
                      <span className="text-danger">{errors}</span>{" "}
                    </div>
                    </React.Fragment>
                  ))}
                </React.Fragment>   
                
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary btn-save-user" onClick={event => {this.saveUser();}}>Confirm</button>
              </Modal.Footer>

            </React.Fragment>  
          } 
        </Modal>
      </div>
    );
  }
}
export default DeleteUserModal;
