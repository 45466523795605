import React from "react";
import { Link, Redirect } from 'react-router-dom';
import Navbar from './Navbar';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { green, orange, blue } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import {RegisterApi} from './register.api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

class SubscriptionPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected_service: {_id: '0', name: 'Cloud Email', 'checked' : false, code: 'test1', amount: 99.00, or: 150},
      paypal_subscriptions: [],
      rows: [],
      quantity: 1,
      redirect: '',
      loading: true,
      transaction: {},
      user: {}
    };
  }

  setOpen(open, tr_i){
    console.log(open);
    let paypal_subscriptions = [];
    if(open)
    {
      open = false;
    }
    else
    {
      open = true;
    }

    this.state.paypal_subscriptions.map((sub, i)=>{
      if(i == tr_i)
      {
        sub.open = open;
      }
      else{
        sub.open = false;
      }
      paypal_subscriptions.push(sub);
    });

    this.setState({ paypal_subscriptions: paypal_subscriptions });

  }

  componentDidMount() {
    
    this.setState({ loading: true });
    RegisterApi.paypalGetSubscriptions({}).then( r => {
      this.setState({ loading: false, paypal_subscriptions: r.data.paypal_subscriptions });
      console.log(r.data.paypal_subscriptions);
    }).catch(error => {
      localStorage["appState"] = JSON.stringify({isLoggedIn: false,user: {}});
      window.location.pathname = '/login'; 
    });
  }

  render() {
    const outerTheme = createMuiTheme({
      palette: {
        primary: {
          main: blue[500],
        },
      },
    });

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (

      <React.Fragment>  
      <Navbar /> 
      
        <div className="app-content content app-content-register">
            <div className="content-wrapper">
                <div className="content-wrapper-before"></div>
                <div className="content-header ">
                    <div className="content-header-left breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">Subscriptions</h3>
                    </div>
                </div>
                <div className="content-body">
                    <section className="row">
                            <TableContainer component={Paper}>
                              <Table  aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell><b>Subscription</b></TableCell>
                                    <TableCell><b>Next Billing</b></TableCell>
                                    <TableCell />
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                { this.state.loading ? 
                                  <TableRow>
                                    <TableCell><Skeleton /></TableCell>
                                    <TableCell><Skeleton /></TableCell>
                                    <TableCell><Skeleton /></TableCell>
                                  </TableRow>
                                  :
                                  <React.Fragment> 
                                    {this.state.paypal_subscriptions.length == 0 ? 
                                      <TableRow>
                                        <TableCell colSpan="6"><center>No record found.</center></TableCell>
                                      </TableRow>
                                      : void 0
                                    }
                                    {this.state.paypal_subscriptions.map((row, i) => (
                                      <React.Fragment>  
                                      <TableRow key={row.name}>
                                        
                                        <TableCell >
                                          {row.plan.name}
                                        </TableCell>
                                        <TableCell>{row.paypal_next_billing_date}</TableCell>
                                        <TableCell>
                                          <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(row.open, i)}>
                                            {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                          <Collapse in={row.open} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                              <Typography variant="h7" gutterBottom component="div">
                                                Billing Info
                                              </Typography>
                                              <Table size="small" >
                                                <TableBody>
                                                    <TableRow>
                                                      <TableCell style={{ borderBottom: 0}}>Outstanding Balance</TableCell>
                                                      <TableCell style={{ borderBottom: 0}}><span className="pull-right">{row.paypal_agreement_response.billing_info.outstanding_balance.value} PHP</span></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                      <TableCell style={{ borderBottom: 0}}>Last Payment</TableCell>
                                                      <TableCell style={{ borderBottom: 0}}><span className="pull-right">{row.paypal_agreement_response.billing_info.last_payment.amount.value} PHP</span></TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                              </Table>
                                            </Box>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                      </React.Fragment>  
                                    ))} 
                                  </React.Fragment> 
                                }
                                  
                                </TableBody>
                              </Table>
                            </TableContainer>

                    </section>
                </div>
            </div>
          </div>
      </React.Fragment>    
    );
  }
}

export default SubscriptionPage;
