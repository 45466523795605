import http from '../../Services/api.laravel.http';
import $ from "jquery";
var qs = require('qs');

export const roleApi = {
  getAccess: () => http.get('/admin/role/default/access'),
  list: (service) => http.get('/admin/role', {params: service,paramsSerializer: params => {return qs.stringify(params) }}),
  saveRole: (service) => http.post('/admin/role', service),
  getRole: (_id) => http.get('/admin/role/' + _id),
  updateRole: (_id, service) => http.put('/admin/role/' + _id, service),
  deleteRole: (_id) => http.delete('/admin/role/' + _id),
  getAllRole: () => http.get('/admin/role/get/all'),
  saveRank: (rank) => http.post('/admin/role/save/rank', rank),
}
