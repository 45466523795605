import http from '../../Services/api.laravel.http';
import $ from "jquery";
var qs = require('qs');

export const subscriberAPI = {
  list: (subscriber) => http.get('/subscription/subscriber',{params: subscriber,paramsSerializer: params => {return qs.stringify(params) }} ),
  getServices: () => http.get('/subscription/subscriber/get/services'),
  saveSubscriber: (subscriber) => http.post('/subscription/subscriber', subscriber),
  getSubscriber: (_id) => http.get('/subscription/subscriber/' + _id),
  saveSubscriberUpdate: (access, _id) => http.put('/subscription/subscriber/' + _id, access),
  saveSubscriberDelete: (_id) => http.delete('/subscription/subscriber/' + _id ),
  syncSubscriber: (access) => http.post('/subscription/subscriber/sync/default', access),
}
