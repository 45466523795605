import React from "react";
import { Link, Redirect } from 'react-router-dom';
import Navbar from './Navbar';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { green, orange, blue } from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import {RegisterApi} from './register.api';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

class Complete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selected_service: {_id: '0', name: 'Cloud Email', 'checked' : false, code: 'test1', amount: 99.00, or: 150},
      quantity: 1,
      redirect: '',
      loading: true,
      transaction: {},
      user: {}
    };
  }

  componentDidMount() {
    
    let subscription_id = new URLSearchParams(this.props.history.location.search).get('subscription_id');
    let ba_token = new URLSearchParams(this.props.history.location.search).get('ba_token');
    let token = new URLSearchParams(this.props.history.location.search).get('token');

    console.log(subscription_id);

    if(subscription_id)
    {
      RegisterApi.paypalExecute({subscription_id: subscription_id, ba_token: ba_token, token: token}).then( r => {
        
        if(r.data.status == 1)
        {
          localStorage["selectedService"] = JSON.stringify({});
          this.setState({'loading' : false, transaction: r.data.PaypalTransaction, user: r.data.user});
        }
        else
        {
          // this.setState({'redirect' : '/payment'});
        }
      });
    }
    else
    {
      this.setState({'redirect' : '/payment'});
    }
  }

  render() {

    let steps = ['Create account', 'Select service', 'Subscribe', 'Complete'];

    const outerTheme = createMuiTheme({
      palette: {
        primary: {
          main: blue[500],
        },
      },
    });

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (

      <React.Fragment>  
      <Navbar /> 
      { this.state.loading ? <center><CircularProgress className="mt-5 mx-auto"  /></center> : 
        <div className="app-content content app-content-register">
            <div className="content-wrapper">
                <div className="content-wrapper-before"></div>
                <div className="content-header ">
                    <div className="content-header-left breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">Complete</h3>
                    </div>
                    <Stepper activeStep={4} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                <div className="content-body">
                    <section className="row">
                        <div className="col-sm-12">
                            <div id="what-is" className="card card-register">
                                
                                <div className="card-content collapse show">
                                    <div className="card-body">
                                      <center>
                                        <h4>Thank you for your payment</h4>
                                        <br/>
                                        <p>Your Transaction#  is <u>{this.state.transaction.subscription_id}</u></p>
                                        <p>A copy of this transaction has been sent to your email <u>{this.state.user.admin_email}</u></p>

                                        <p>Your next billing cycle will be on {this.state.transaction.paypal_next_billing_date}</p>
                                        <p>For any further questions and inquiries please email us at <a href="mailto:support@bluehive.com.ph">support@bluehive.com.ph</a> or chat whit us <a href="https://www.facebook.com/bluehivesystems/"></a> </p>

                                        <br/> <br />
                                        <p>Click <Link to="/subscriptions">here</Link> to view your subscriptions</p>
                                      </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
          </div>
        }
      </React.Fragment>    
    );
  }
}

export default Complete;
