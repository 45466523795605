import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import PropTypes from 'prop-types';
import {accountsAPI} from './Account.service';
import Select from 'react-select';

class CreateUserModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowCreateUserModal = this.handleShowCreateUserModal.bind(
      this
    );
    this.handleCloseCreateUserModal = this.handleCloseCreateUserModal.bind(
      this
    );
    this.state = {
      show: false, loading: false,
      roles : [], form_error: [], roles: [], errors: [], roleOptions: [],
      role_id: '','name' : '','birthday' : '','contact_number' : '','email' : '','password' : '',
    };
  }

  static propTypes = { getUserList: PropTypes.func.isRequired, }

  componentWillReceiveProps(newProps) { }
  handleShowCreateUserModal() { this.setState({ show: true }); this.getRoles(); }
  handleCloseCreateUserModal() { this.setState({ show: false }); }
  handleChange = prop => event => { this.setState({ [prop]: event.target.value }); };
  handleSelectRole(value) { this.setState({ role_id: value.value}) }

  getRoles = async () => {
    this.setState({ loading: true, form_error: [] });
    accountsAPI.getRoles().then( r => {
      if(r.data.roles)
      {
        let roles = r.data.roles; let roleOptions = [];

        roles.map((role, i) => { roleOptions[i] = { value: role._id, label: role.admin_role, index: i}; });

        this.setState({roleOptions: roleOptions, roles: roles, loading:false});

      }
    }).catch(error => { let form_error = ["Network error, please reload the page."]; this.setState({ loading: false, form_error: form_error });  });
  }



  saveUser = event => {
    $(".btn-save-user").attr("disabled", "disabled").html("Creating...");
    console.log('saveUser');
    this.setState({ loading: true });

      let postParams = {
        birthday : this.state.birthday,
        name : this.state.name,
        contact_number : this.state.contact_number,
        username : this.state.email,
        password : this.state.password,
        role_id : this.state.role_id,
      }

      accountsAPI.saveUser(postParams)
      .then(ret => {
        if (ret.data.status == 1) 
        {
          this.setState({ birthday : '', name : '', contact_number : '', username : '', password : '', role_id : '', loading:false, show: false, form_error: []});
          this.props.getUserList();
        } 
        else 
        {
          this.setState({ form_error: ret.data.errors, success_message: "", loading: false });
        }
        $(".btn-save-user").removeAttr("disabled").html("Save");
      })
      .catch(error => {
        let errors = ["Network error, please reload the page or you have entered an invalid API"];
        this.setState({ form_error: errors, success_message: "" , loading: false});
        $(".btn-save-user").removeAttr("disabled").html("Save");
      });
  };



  render() {
    return (
      <div>
        <Modal
          size="sm"
          show={this.state.show}
          onHide={this.handleCloseCreateUserModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.loading ? ( <Spinner /> ) : 
            <React.Fragment>
              <div className='row'>
                <div className='col-md-12'>
                  <label>Name</label>
                  <input type="text" className="form-control" name="name" value={this.state.name}  onChange={this.handleChange("name")} />
                </div>
                <div className="col-md-12">
                  <label>Date of birth</label>
                  <input type="date" className="form-control" name="subscriber_birthday" value={this.state.birthday} onChange={this.handleChange("birthday")} />
                </div>
                <div className="col-md-12">
                  <label>Contact Number</label>
                  <input type="number" className="form-control" name="contact_number" value={this.state.contact_number} onChange={this.handleChange("contact_number")} />
                </div>
                <div className="col-md-12">
                  <label>Username</label>
                  <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.handleChange("email")} />
                </div>
                <div className="col-md-12">
                  <label>Password</label>
                  <input type="password" className="form-control" name="password" value={this.state.password} onChange={this.handleChange("password")} />
                </div>
                <div className='col-md-12'>
                    <label>Role</label>
                    <Select options={this.state.roleOptions} onChange={value => this.handleSelectRole(value)} />
                </div>
              </div>
              
               {this.state.form_error.map((errors, index) => (
                <React.Fragment key={index}>
                <div className="col-md-12">
                  <span className="text-danger">{errors}</span>{" "}
                </div>
                </React.Fragment>
              ))}
            </React.Fragment>   
            } 
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-save-user" onClick={event => {this.saveUser();}}>Save</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default CreateUserModal;
