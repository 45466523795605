import http from '../Services/api.laravel.http';

export const RegisterApi = {
  register: (form) => http.post('/user/register', form),
  serviceList: (user) => http.post('/get/services', user),
  paypalCheckout: (form) => http.post('/admin/paypal/checkout', form),
  paypalExecute: (form) => http.post('/admin/paypal/execute', form),
  paypalGetSubscriptions: (form) => http.post('/admin/paypal/subscriptions', form),
  userProfile: (form) => http.post('/admin/user/profile', form),
  userProfileSave: (form) => http.post('/admin/user/profile/save', form)
}