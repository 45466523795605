import React from "react";
import axios from "axios";
import {systemAccessApi} from './SystemAccess.service';
import CreateSystemAccessModal from './SystemAccess.modal.create';
import EditSystemAccessModal from './SystemAccess.modal.edit';
import DeleteSystemAccessModal from './SystemAccess.modal.delete';

import Spinner from "../../Includes/Spinner";
import Pagination from "react-js-pagination";
import Parser from 'html-react-parser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export default class SystemAccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      search: "",
      loading: true,
      service_access_list: [],
      service_access_list_paginate: [],
      success_message: '',
      loading: true,
      _id: ''
    };
  }

  CreateSystemAccessModalRef = ({handleShowCreateAccessModal}) => {
     this.showModal = handleShowCreateAccessModal;
  }

  DeleteSystemAccessModalRef = ({handleShowDeleteAccessModal}) => {
     this.showModalDelete = handleShowDeleteAccessModal;
  }

  EditSystemAccessModalRef = ({handleShowEditAccessModal}) => {
     this.showModalEdit = handleShowEditAccessModal;
  }

  onDeleteModalOpenClick =  (_id) => {

    console.log('a');
    this.showModalDelete(_id);
   }

  onEditModalOpenClick =  (_id) => {
    this.showModalEdit(_id);
   }


  onCreateModalOpenClick = () => {
    this.showModal();
   }


   syncServiceRoleApi =  (key, _id) => {

    let system_access = this.state.service_access_list;
    system_access[key].syncing = true;
    this.setState({ service_access_list: system_access });

    let postParams = { id: _id};

    systemAccessApi
      .syncSystemAccess(postParams)
      .then(r => {
        system_access[key].syncing = false;
        this.setState({ service_access_list: system_access });
      })
      .catch(error => {
        alert("Something went wrong, please reload the page");
        this.setState({ loading: false });
      });

    this.setState({ service_access_list: system_access });
   }


   getSystemAccessList = async () => {
    this.setState({ loading: true });
    systemAccessApi
      .list({ page: this.state.activePage, search: this.state.search })
      .then(r => {
        console.log(r);
        if (r.data.status == 1) {
          this.setState({
            service_access_list: r.data.service_access_list.data,
            service_access_list_paginate: r.data.service_access_list,
            loading: false
          });
        }
      })
      .catch(error => {
        alert("Something went wrong, please reload the page");
        this.setState({ loading: false });
      });
  }

  handleSearch = prop => event => {
    this.setState(
      {
        search: event.target.value,
        activePage: 1,
        'success_message': ''
      },
      () => {
        this.getSystemAccessList();
      }
    );
  };

  handlePageChange = prop => event => {
    this.setState(
      {
        activePage: event,
        'success_message': ''
      },
      () => {
        this.getSystemAccessList();
      }
    );
  };

   componentDidMount() {
     this.getSystemAccessList();
   }

  render() {
    return (
      <React.Fragment>
       <div className="app-content content">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header row">
                  <div className="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Systems Access</h3>
                      <div className="breadcrumbs-top d-inline-block">
                          <div className="breadcrumb-wrapper mr-1">
                              <ol className="breadcrumb">
                                  <li className="breadcrumb-item"><a href="/subscription/system/access">List</a></li>
                              </ol>
                          </div>
                      </div>
                  </div>
                  <div className="content-header-right col-md-4 col-12 d-block "><a className="btn btn-primary btn-min-width float-right box-shadow-4 mr-1 mb-1" data-toggle="modal" data-target="#new-service-modal" href="#" onClick={this.onCreateModalOpenClick} ><i className="ft-plus"></i> Create System Access</a></div>
              </div>
              <div className="content-body">
                  <section className="row">
                      <div className="col-sm-12">
                          <div id="what-is" className="card">
                              <div className="card-header">
                                  <h4 className="card-title">Systems Access Management</h4>
                                  <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                              </div>
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                      <div className="card-text table-responsive">
                                          <table className="table table-bordered table-hover table-striped table-condensed ">
                                            <thead>
                                              <tr>
                                                <th colSpan="20">
                                                  <div className="col-sm-3 col-md-3 float-md-right">
                                                    <input
                                                      type="text"
                                                      className="form-control input-sm"
                                                      placeholder="Search.."
                                                      onChange={this.handleSearch()}
                                                    />
                                                  </div>
                                                </th>
                                              </tr>
                                              <tr className="text-center">
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th>Api Link</th>
                                                <th>Status</th>
                                                <th>Access</th>
                                                <th></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.loading ? (
                                                <tr><td colSpan="20"><Spinner /></td></tr>
                                                            ) :
                                               <React.Fragment>
                                                {Object.keys(this.state.service_access_list).length == 0? <tr><td colSpan="20"><center>---No data---</center></td></tr> :null }
                                                {this.state.service_access_list.map((list, key) => (
                                                  <React.Fragment key={list._id}>
                                                   <tr>
                                                      <td>{list.system_access_name}</td>
                                                      <td>{list.system_access_description}</td>
                                                      <td>{list.system_access_api_link}</td>
                                                      <td>{ list.system_access_status == 0 ? ( <span className='text-success'>Active</span> ) : ( <span className='text-danger'>Disabled</span>) }</td>
                                                      <td>
                                                      { Parser(list.system_access_default) }
                                                      </td>
                                                      <td>
                                                        {list.syncing ? ( <Spinner /> ) : 
                                                          <React.Fragment>
                                                            <button className="btn btn-primary margin-5" onClick={event => { this.syncServiceRoleApi(key, list._id); }} data-toggle="modal" data-target="#new-service-modal">
                                                              <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSync} />
                                                            </button>
                                                            <button className="btn btn-primary margin-5" onClick={event => { this.onEditModalOpenClick(list._id); }} data-toggle="modal" data-target="#new-service-modal">
                                                              <i className="ft-edit"></i>
                                                            </button>
                                                            <button className="btn btn-danger margin-5" onClick={event => { this.onDeleteModalOpenClick(list); }} data-toggle="modal" data-target="#remove-service-role-modal">
                                                              <i className="ft-trash"></i>
                                                            </button>
                                                          </React.Fragment>
                                                        }
                                                        
                                                      </td>
                                                    </tr>
                                                  </React.Fragment>
                                                ))}
                                                </React.Fragment>
                                              }



                                              <tr>
                                                <td colSpan="20">
                                                  <div className="d-flex justify-content-center">
                                                    <Pagination
                                                      activePage={this.state.activePage}
                                                      itemsCountPerPage={10}
                                                      totalItemsCount={this.state.service_access_list_paginate.total}
                                                      pageRangeDisplayed={10}
                                                      onChange={this.handlePageChange()}
                                                      itemClass={"page-item"}
                                                      linkClass={"page-link"}
                                                      className={"pagination"}
                                                    />
                                                  </div>  
                                                </td>
                                              </tr>
                                            </tbody>

                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
        </div>
        
        <DeleteSystemAccessModal  ref={this.DeleteSystemAccessModalRef}   getSystemAccessList={this.getSystemAccessList} ></DeleteSystemAccessModal>
        <CreateSystemAccessModal  ref={this.CreateSystemAccessModalRef}   getSystemAccessList={this.getSystemAccessList} ></CreateSystemAccessModal>
        <EditSystemAccessModal  ref={this.EditSystemAccessModalRef}     getSystemAccessList={this.getSystemAccessList} ></EditSystemAccessModal>

        </React.Fragment>
    );
  }
}