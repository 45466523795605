import React from "react";
import axios from "axios";
import {_home} from './home.service';

const API_ROOT = process.env.REACT_APP_API_LINK || 'inventorylumen.test/api';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: JSON.parse(localStorage["appState"]).user.auth_token,
      users: []
    };
  }

  componentDidMount() {
    _home.ping().then( r => {
      console.log(r);
    });
  }

  render() {
    return (
      <React.Fragment>  
        <div className="app-content content">
          <div className="content-wrapper">
              <div className="content-wrapper-before"></div>
              <div className="content-header row">
                  <div className="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                      <h3 className="content-header-title mb-0 d-inline-block">Dashboard</h3>
                  </div>
                  <div className="content-header-right col-md-4 col-12 d-block d-md-none"><a className="btn btn-warning btn-min-width float-md-right box-shadow-4 mr-1 mb-1" href="chat-application.html"><i className="ft-mail"></i> Email</a></div>
              </div>
              <div className="content-body">
                  <section className="row">
                      <div className="col-sm-12">
                          <div id="what-is" className="card">
                              <div className="card-header">
                                  <h4 className="card-title">Welcome to Bluehive Subscription Management</h4>
                                  <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a>
                              </div>
                              <div className="card-content collapse show">
                                  <div className="card-body">
                                      <div className="card-text">
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
              </div>
          </div>
        </div>
      </React.Fragment>    
    );
  }
}

export default Home;
