import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { systemAccessApi } from "./SystemAccess.service";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import PropTypes from 'prop-types';
import {serviceApi, createButtonService, handleChange, addNewTier, saveService, removeTier, handleChangeNewTier} from './Service.service';
import Select from 'react-select'

class EditServiceModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowEditServiceModal = this.handleShowEditServiceModal.bind(
      this
    );
    this.handleCloseEditServiceModal = this.handleCloseEditServiceModal.bind(
      this
    );
    this.state = {
      form_error: [],
      show: false,
      _id: '',
      'service_name': '',
      'service_name' : '',
      'service_price' : 0,
      'service_discount': 0,
      'service_show_in': 'public',
      'newTier': [
        { tier_name: '', tier_price: 0, service_role_id: ''}
      ],
      'roles' : [],
      loading: false,
      errors: [],
      options: []
    };
  }

  static propTypes = {
    getServicesList: PropTypes.func.isRequired,
  }

  componentWillReceiveProps(newProps) {

    let newTier = this.state.newTier;

    newTier.map((tier, key) => {
      if(tier.role == '')
      {
        newTier[key].role = newProps.first_role;
      }
    });
  }

  handleShowEditServiceModal(service, roles) {

    this.setState({
      _id: service._id,
      service_show_details: service.service_show_details,
      service_name: service.service_name,
      service_price: service.service_price,
      service_discount: service.service_discount,
      service_show_in: service.service_show_in,
    })

    let newTier = {}

    service.tier.map((tier, i) => {
      service.tier[i].selected_role = { value: tier.role._id, label: tier.role.service_role_name};
    });

    let roleSelect = [];
    roles.map((role, i) => {
        roleSelect[i] = { value: role._id, label: role.service_role_name};
    });

    this.setState({ show: true, roleSelect : roleSelect, newTier:  service.tier}, ()=> {console.log(this.state);});
  }

  handleCloseEditServiceModal() {
    this.setState({ show: false });
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };


  handleSelectRole(value, key) {

    let newTier = this.state.newTier;

    newTier[key].service_role_id = value.value;
    this.setState({ newTier: newTier }, () => { console.log(this.state.newTier)});
  }


  saveService = event => {
    $(".btn-save-edit-service").attr("disabled", "disabled").html("Saving...");

    this.setState({ loading: true });
    let postParams = {
      _id : this.state._id,
      service_show_details: this.state.service_show_details,
      service_name: this.state.service_name,
      service_price: this.state.service_price,
      service_discount: this.state.service_discount,
      service_show_in: this.state.service_show_in,
      service_tier: this.state.newTier,
    };

    serviceApi
      .updateService(this.state._id, postParams)
      .then(ret => {
        if (ret.data.status == 1) {

          this.setState({
            'service_name': '',
            'service_name' : '',
            'service_price' : 0,
            'service_discount': 0,
            'service_show_in': 'public',
            'newTier': [
              { name: '', price: 0, role: ''}
            ],
            loading: false,
            show: false,
            errors: []
          });
          this.props.getServicesList();
          $(".btn-save-edit-service").removeAttr("disabled").html("Save");
        } else 
        {
          this.setState({ form_error: ret.data.errors, success_message: "", loading: false });
          this.props.getServicesList();
        }
        $(".btn-save-edit-service").removeAttr("disabled").html("Save");
      })
      .catch(error => {
        console.log(error);
        let errors = ["Network error, please reload the page or you have entered an invalid API"];
        this.setState({ form_error: errors, success_message: "" , loading: false});
        $(".btn-save-edit-service").removeAttr("disabled").html("Save");
      });
  };



  render() {
    return (
      <div>
        <Modal
          size="xl"
          show={this.state.show}
          onHide={this.handleCloseEditServiceModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Service</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.loading ? ( <Spinner /> ) : 
            <React.Fragment>
              <div className='row'>
                <div className='col-md-12 row'>
                  <div className='col-md-6'>
                    <label>Name</label>
                    <input type='text' className='form-control' value={ this.state.service_name } onChange={ handleChange(this, 'service_name') } />
                  </div>
                  <div className='col-md-6'>
                    <label>Price</label>
                    <input type='number' className='form-control' value={ this.state.service_price } onChange={ handleChange(this, 'service_price') } />
                  </div>
                  <div className='col-md-6'>
                    <label>Discount (%)</label>
                    <input type='number' className='form-control' value={ this.state.service_discount } onChange={ handleChange(this, 'service_discount') }  />
                  </div>
                  <div className='col-md-6'>
                    <label>Show in:</label>
                    <select className='form-control' value={ this.state.service_show_in } onChange={ handleChange(this, 'service_show_in') } >
                      <option value="public"  >Public</option>
                      <option value="private" >Private</option>
                    </select>
                    <center><small><span className='text-default'>Private will not be viewable in frontend</span></small></center> 
                  </div>
                  <div className='col-md-12' >
                    <label>Details</label>
                    <textarea className='form-control' value={ this.state.service_show_details} onChange={ handleChange(this, 'service_show_details') }>{ this.state.service_show_details}</textarea>
                  </div>
                </div>
                <div className='col-md-12 row'>
                  <div className='col-md-12 table-responsive'>
                    <br />
                    <small>
                      <table className='table table-condensed table-sm table-striped table-bordered' >
                        <thead>
                          <tr>
                            <th colSpan="20" className="five-padding">
                              <center>Tier</center>

                            </th>
                          </tr>
                          <tr>
                            <th className="five-padding" >Name</th>
                            <th className="five-padding" >Price</th>
                            <th className="five-padding" >Role</th>
                            <th className="five-padding" ></th>
                          </tr>
                        </thead>
                        <tbody>
                         {this.state.newTier.map((tier, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td className="five-padding" ><input type='text' className='form-control'  value={tier.tier_name} onChange={ handleChangeNewTier(this, i, 'tier_name') } /></td>
                              <td className="five-padding" ><input type='number' className='form-control' value={tier.tier_price} onChange={ handleChangeNewTier(this, i, 'tier_price') }  /></td>
                              <td className="five-padding" >
                                <Select defaultValue={tier.selected_role} options={this.state.roleSelect} onChange={value => this.handleSelectRole(value, i)} />
                              </td>
                              <td className="five-padding" ><button className='btn btn-danger btn-sm' onClick={event => { removeTier(this, i) }} ><i className="ft-trash-2"></i></button></td>
                            </tr>
                          </React.Fragment>  
                          ))}
                          
                          <tr>
                            <td colSpan="3" className="five-padding">
                              
                            </td>
                            <td className="five-padding" >
                              <button className='btn btn-primary btn-sm' onClick={event => { addNewTier(this) }} ><i className="ft-plus"></i></button> 
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </small>
                  </div>
                </div>
              </div>
               {this.state.form_error.map((errors, index) => (
                <React.Fragment key={index}>
                <div className="col-md-12">
                  <span className="text-danger">{errors}</span>{" "}
                </div>
                </React.Fragment>
              ))}
            </React.Fragment>   
            } 
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-save-edit-service" onClick={event => {this.saveService();}}>Save</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default EditServiceModal;
