import React from "react";
import axios from "axios";
import CheckboxTree from "react-checkbox-tree";
import Pagination from "react-js-pagination";
import Spinner from "../../Includes/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
// import { accounts } from "./_service.service";
import { Modal, Button } from "react-bootstrap";
import { role } from "./Role.service";
import { faHome, faCheckSquare, faSquare, faChevronRight, faChevronDown, faPlusSquare, faMinusSquare, faFolder, faFolderOpen, faFile } from "@fortawesome/free-solid-svg-icons";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-checkbox-tree/src/less/react-checkbox-tree.less";
import "react-checkbox-tree/src/scss/react-checkbox-tree.scss";

export default class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: JSON.parse(localStorage["appState"]).user.auth_token,
      nodes: [],
      default_nodes: [],
      checked: [],
      expanded: [],
      default_expanded: [],
      icons: [],
      role_name: "",
      role_description: "",
      form_error: [],
      success_message: "",
      service_role_list: [],
      service_role_paginate: [],
      activePage: 1,
      search: "",
      loading: true,
      mode: 'create',
      '_id' : "",
      '_remove' : []
    };
  }

  handlePageChange = prop => event => {
    this.setState(
      {
        activePage: event,
        'success_message': ''
      },
      () => {
        this.getServiceRoleList();
      }
    );
  };

  handleSearch = prop => event => {
    console.log(event.target.value);
    this.setState(
      {
        search: event.target.value,
        activePage: 1,
        'success_message': ''
      },
      () => {
        this.getServiceRoleList();
      }
    );
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  saveRole = event => {
    $(".btn-save-create-role")
      .attr("disabled", "disabled")
      .html("Saving Role...");

    let postParams = {
      service_role_name: this.state.role_name,
      service_role_description: this.state.role_description,
      service_role_access: this.state.checked,
      _id: this.state._id
    };

    // Update
    if(this.state._id)
    {
      console.log(1);
      role
      .saveRoleEdit(postParams)
      .then(r => {
        if (r.data.status == 1) {
          this.setState({ form_error: [], success_message: r.data.message });
          $(".btn-close-modal-create-role").click();

          let resetInput = {
            role_name: '',
            role_description: '',
            checked: [],
            _id: ""
          };

          this.setState(resetInput);

        } else {
          this.setState({ form_error: r.data.errors, success_message: "" });
        }
        $(".btn-save-create-role").removeAttr("disabled").html("Save");
        console.log(this.state);
        this.getServiceRoleList();
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "" });
      });
    }
    // Create
    else
    {
      role
      .saveRole(postParams)
      .then(r => {
        if (r.data.status == 1) {
          this.setState({ form_error: [], success_message: r.data.message });
          $(".btn-close-modal-create-role").click();

          let resetInput = {
            role_name: '',
            role_description: '',
            checked: [],
            _id: ""
          };

          this.setState(resetInput);

        } else {
          this.setState({ form_error: r.data.errors, success_message: "" });
        }
        $(".btn-save-create-role").removeAttr("disabled").html("Save");
        console.log(this.state);
        this.getServiceRoleList();
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "" });
      });
    }
    
  };

  createRoleButton = event => {
    this.setState({ nodes: this.state.default_nodes, expanded: this.state.default_expanded, checked : [], role_name: '', role_description: ''});
    this.setState({ mode : 'create', _id: '', form_error: []});
  }
  getServiceRoleApi = event => {

    this.setState({ loading: true, mode: 'edit', '_id' : event, form_error : []});

    role.getRole(event).then( r => {
      console.log(r);
      if(r.data.status == 1)
      {
        this.setState({ loading: false, mode: 'edit', checked: r.data.serviceRole.service_role_access, role_name: r.data.serviceRole.service_role_name , role_description: r.data.serviceRole.service_role_description  });
      }
    });
  }


  confirmDeleteRole = event => {
    

    this.setState({'_remove': event});
  };

  deleteRole =  event =>{
      console.log(this.state._remove);

      role
      .deleRole(this.state._remove._id)
      .then(r => {
        this.setState({_remove: [], success_message: r.data.message});
        $(".btn-close-modal-create-role").click();

        this.getServiceRoleList();
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "" });
      });

  }

  getServiceRoleList() {

    this.setState({ loading: true });
    console.log(this.state);
    role
      .list({ page: this.state.activePage, search: this.state.search })
      .then(r => {
        console.log(r);
        if (r.data.status == 1) {
          this.setState({
            service_role_list: r.data.service_role_list.data,
            service_role_paginate: r.data.service_role_list,
            loading: false
          });
          console.log(this.state);
        }
      })
      .catch(error => {
        alert("Something went wrong, please reload the page");

        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    let nodes = [],
      expanded = [];

    this.getServiceRoleList();

    role.defaultAccess().then(r => {
      console.log(r);

      if (r.data.nodes) {
        nodes = r.data.nodes;
        expanded = r.data.expanded;
        this.setState({ nodes: nodes, expanded: expanded, default_nodes: nodes, default_expanded: expanded});

        console.log(this.state);
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="app-content content">
          <div className="content-wrapper">
            <div className="content-wrapper-before"></div>
            <div className="content-header row">
              <div className="content-header-left col-md-8 col-12 mb-2 breadcrumb-new">
                <h3 className="content-header-title mb-0 d-inline-block">
                  Services
                </h3>
                <div className="breadcrumbs-top d-inline-block">
                  <div className="breadcrumb-wrapper mr-1">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/subscription/role">Role</a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="content-header-right col-md-4 col-12 d-block ">
                <a
                  className="btn btn-primary btn-min-width float-right box-shadow-4 mr-1 mb-1"
                  data-toggle="modal"
                  data-target="#new-service-modal"
                  href="#"
                  onClick={event => { this.createRoleButton(); }}
                >
                  <i className="ft-plus"></i> Create Role
                </a>
              </div>
            </div>
            <div className="content-body">
              <section className="row">
                <div className="col-sm-12">
                  <div id="what-is" className="card">
                    <div className="card-header">
                      <h4 className="card-title">Services Role Management</h4>
                      <a className="heading-elements-toggle">
                        <i className="la la-ellipsis-v font-medium-3"></i>
                      </a>
                    </div>
                    <div className="card-content collapse show">
                      <div className="card-body">
                        <div className="card-text table-responsive">
                          <table className="table table-bordered table-hover table-striped table-condensed table-sm">
                            <thead>
                              <tr>
                                <th colSpan="20">
                                  <span className="text-success">
                                    {this.state.success_message}
                                  </span>
                                  <div className="col-sm-3 col-md-3 float-md-right">
                                    <input
                                      type="text"
                                      className="form-control input-sm"
                                      placeholder="Search.."
                                      onChange={this.handleSearch()}
                                    />
                                  </div>
                                </th>
                              </tr>
                              <tr className="text-center">
                                <th>Name</th>
                                <th>Description</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody className={ this.state.loading ? '' : 'd-none'} >
                              <tr  >
                                <td colSpan="20">
                                  <Spinner />
                                </td>
                              </tr>
                            </tbody>
                            <tbody className={ this.state.loading ? 'd-none' : ''}>
                              {Object.keys(this.state.service_role_list).length == 0? <tr><td colSpan="20"><center>---No data---</center></td></tr> :null }
                              {this.state.service_role_list.map(role => (
                                <tr>
                                  <td>{role.service_role_name}</td>
                                  <td>{role.service_role_description}</td>
                                  <td>
                                    <button className="btn btn-primary" onClick={event => { this.getServiceRoleApi(role._id); }} data-toggle="modal" data-target="#new-service-modal">
                                      <i className="ft-edit"></i>
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-danger" onClick={event => { this.confirmDeleteRole(role); }} data-toggle="modal" data-target="#remove-service-role-modal">
                                      <i className="ft-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan="20">
                                  <div className="d-flex justify-content-center">
                                    <Pagination
                                      activePage={this.state.activePage}
                                      itemsCountPerPage={10}
                                      totalItemsCount={this.state.service_role_paginate.total}
                                      pageRangeDisplayed={10}
                                      onChange={this.handlePageChange("role_name")}
                                      itemClass={"page-item"}
                                      linkClass={"page-link"}
                                      className={"pagination"}
                                    />
                                  </div>  
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div id="new-service-modal" className="modal" tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-dialog-centered modal-md"role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{this.state.mode == 'create' ? 'New Role' : 'Edit Role'}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">

                <div className={ this.state.loading ? '' : 'd-none'}>
                  <Spinner />
                </div>
              
                <div className={ this.state.loading ? 'd-none row' : 'row'}>
                  <div className="col-md-12">
                    <center>Details</center>
                  </div>
                  <div className="col-md-12">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="role_name"
                      value={this.state.role_name}
                      onChange={this.handleChange("role_name")}
                    />
                  </div>
                  <div className="col-md-12">
                    <label>Description</label>
                    <input
                      type="text"
                      className="form-control"
                      name="role_description"
                      value={this.state.role_description}
                      onChange={this.handleChange("role_description")}
                    />
                  </div>
                  <div className="col-md-12">
                    <hr />
                    <label>Access </label>

                    <CheckboxTree
                        icons={{ check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} />, uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />, halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />, expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />, expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />, expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />, collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />, parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />, parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />, leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} /> }}
                        nodes={this.state.nodes}
                        checked={this.state.checked}
                        expanded={this.state.expanded}
                        onCheck={checked => this.setState({ checked })}
                        onExpand={expanded => this.setState({ expanded })}
                    />
                  </div>

                  {this.state.form_error.map(errors => (
                    <div className="col-md-12">
                      <span className="text-danger">{errors}</span>{" "}
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-save-create-role" onClick={event => { this.saveRole(); }} >Save</button>
                <button type="button" className="btn btn-secondary btn-close-modal-create-role" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div id="remove-service-role-modal" className="modal" tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-dialog-centered modal-md"role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Remove Service Role</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <center>Are you sure you want to remove "{ this.state._remove.service_role_name}"</center>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-save-create-role"  onClick={event => { this.deleteRole(); }} >Confirm</button>
                <button type="button" className="btn btn-secondary btn-close-modal-create-role" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>      
      </React.Fragment>
    );
  }
}
