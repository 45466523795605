import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { systemAccessApi } from "./SystemAccess.service";
import $ from "jquery";
import Spinner from "../../Includes/Spinner";

class DeleteSystemAccessModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowDeleteAccessModal = this.handleShowDeleteAccessModal.bind(this);
    this.handleCloseEditAccessModal = this.handleCloseDeleteAccessModal.bind(this);
    this.state = {
      form_error: [],
      show: false,
      system_access: [],
      system_access_name: "",
      system_access_description: "",
      system_access_api_link: "",
      system_access_status: 0,
      loading: false,
      errors: []
    };
  }
  handleShowDeleteAccessModal(system_access) {
    this.setState({ show: true,  system_access: system_access});
    // systemAccessApi.getSystemAccess(_id).then(ret => { this.setState({ system_access: ret.data.SystemAccess, loading: false}); });
  }
  handleCloseDeleteAccessModal() {
    this.setState({ show: false });
  }

  handleChange = prop => event => {

    let system_access = this.state.system_access;
    system_access[prop] = event.target.value;

    this.setState({ system_access: system_access });
  };

  saveSystemAccessDelete = event => {
    $(".btn-save-edit-systemaccess").attr("disabled", "disabled").html("Deleting...");

    this.setState({ loading: true });
    systemAccessApi
      .saveSystemAccessDelete(this.state.system_access._id)
      .then(ret => {
        let resetInput = {
            system_access_name: "",
            system_access_description: "",
            system_access_api_link: "",
            system_access_status: "",
            form_error: [],
            show: false,
            loading: false
          };

        this.setState(resetInput);

        $(".btn-save-edit-systemaccess").removeAttr("disabled").html("Save");

        this.props.getSystemAccessList();  
      })
      .catch(error => {
        let errors = ["Network error, please reload the page"];
        this.setState({ form_error: errors, success_message: "" , loading: false});
      });
  };

  render() {
    return (
      <div>
        <Modal
          size="sm"
          show={this.state.show}
          onHide={this.handleCloseEditAccessModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Remove System Access</Modal.Title>
          </Modal.Header>
          
          {this.state.loading ? ( <Spinner />  ) : 
            <React.Fragment>
              <Modal.Body>  
              <div className="col-md-12">
                <center>Are you sure you want to remove "{this.state.system_access.system_access_name}"</center>
              </div>
              {this.state.form_error.map(errors => (
                <div className="col-md-12">
                  <span className="text-danger">{errors}</span>{" "}
                </div>
              ))}
              
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger btn-save-edit-systemaccess" onClick={event => {this.saveSystemAccessDelete();}}>Delete</button>
            </Modal.Footer> 

            </React.Fragment>
          } 
          
        </Modal>
      </div>
    );
  }
}
export default DeleteSystemAccessModal;
