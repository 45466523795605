import React from 'react';
import {menus} from './menu.service';
import { If, Then, ElseIf, Else } from 'react-if-elseif-else-render';

class Menu extends React.Component {
  constructor(props) {
    super(props);

    var pathArray = window.location.pathname.split('/');

    this.state = {
        token: JSON.parse(localStorage["appState"]).user.auth_token,
        user: JSON.parse(localStorage["appState"]).user,
        current_url: window.location.pathname,
        first_url_path : pathArray[1],
        access : this.props.access 
    };

    
  }

  componentDidMount() {

 }

  render() {
    return (
      <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow" data-scroll-to-active="true" data-img="/app-assets/images/backgrounds/04.jpg" >
        <div className="navbar-header">
            <ul className="nav navbar-nav flex-row position-relative">
                <li className="nav-item mr-auto">
                    <a className="navbar-brand" href="/"><img className="brand-logo" alt="Chameleon admin logo" src="/app-assets/images/logo/bluehive_logo-80x80-01.png" />
                        <h3 className="brand-text">BLUEHIVE</h3>
                    </a>
                </li>
                <li className="nav-item d-none d-md-block nav-toggle"><a className="nav-link modern-nav-toggle pr-0" data-toggle="collapse"><i className="toggle-icon ft-disc font-medium-3" data-ticon="ft-disc"></i></a></li>
                <li className="nav-item d-md-none"><a className="nav-link close-navbar"><i className="ft-x"></i></a></li>
            </ul>
        </div>
        <div className="navigation-background"></div>
        <div className="main-menu-content">
            <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                {this.props.access.map((acc, i) => ( 
                    this.renderNav(acc)
                ))}
            </ul>
        </div>    
        </div> 
    );
  }

  renderNav(acc) {
    if(acc.hasChildren === false) {
      return <li key={acc.value} className={ 'nav-item ' + (acc.url == this.state.current_url ? 'active' : '') } ><a href={acc.url}><i className={acc.icon} ></i><span className="menu-title" data-i18n="">{acc.label}</span></a></li>;
    } else {
      return (
          <li  key={acc.value} className={ 'nav-item has-sub ' + (this.state.first_url_path == acc.group ? 'open' : '')} ><a href="#"><i className={acc.icon}></i><span className="menu-title" data-i18n="">{acc.label}</span></a>
            <ul className="menu-content">
                {acc.children.map(children => (
                    <li key={children.value} className={ this.state.current_url == children.url ? 'active' : '' } ><a className="menu-item" href={children.url}>{children.label}</a></li>
                ))}
            </ul>
        </li>
      );
    }
  }
}

export default Menu;
