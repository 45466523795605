import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import {subscriberAPI} from './Subscribers.service';
import $ from "jquery";
import Spinner from "../../Includes/Spinner";
import PropTypes from 'prop-types';
import Select from 'react-select'

class CreateSubscriberModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowCreateSubscriberModal = this.handleShowCreateSubscriberModal.bind(
      this
    );
    this.handleCloseCreateSubscriberModal = this.handleCloseCreateSubscriberModal.bind(
      this
    );
    this.state = {
      form_error: [],
      show: false,
      loading: false,
      errors: [],
      subscriber_birthday: '',
      name: '',
      email_notification: '',
      contact_number: '',
      billing_address: '',
      company_name: '',
      company_address: '',
      email: '',
      password: '',
      servicesOptions: [],
      tierOptions: [],
      service_id: '',
      tier_id: '',
    };
  }

  static propTypes = {
    getSubscriberList: PropTypes.func.isRequired,
  }

  handleShowCreateSubscriberModal() {
    this.getServicesList();
    this.setState({ show: true });
  }
  handleCloseCreateSubscriberModal() {
    this.setState({ show: false });
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  getServicesList = event => {
    subscriberAPI.getServices().then(ret => {
        
        let services = ret.data.Services;
        let servicesOptions = [];
        services.map((service, i) => {
            servicesOptions[i] = { value: service._id, label: service.service_name, index: i};
        });

        this.setState({servicesOptions: servicesOptions, services: services});

    }).catch(error => {
      console.log(error);
      let errors = ["Network error, please reload the page or you have entered an invalid API"];
      alert(errors);
    });
  }

  handleSelectService(value) {

    let tier = this.state.services[value.index].tier;
    let tierOptions = [];

    tier.map((tier, i) => {
        tierOptions[i] = { value: tier._id, label: tier.tier_name, index: i};
    });

    this.setState({tierOptions: tierOptions, tierValue: {}, service_id: value.value});
  }
  handleSelectTier(value) {

    this.setState({tierValue: value, tier_id: value.value});
  }


  saveSubscriberCreate = event => {
    $(".btn-save-subscriver").attr("disabled", "disabled").html("Creating...");

    this.setState({ loading: true });

    let postParams = {
      subscriber_birthday : this.state.subscriber_birthday,
      name : this.state.name,
      email_notification : this.state.email_notification,
      contact_number : this.state.contact_number,
      billing_address : this.state.billing_address,
      company_name : this.state.company_name,
      company_address : this.state.company_address,
      username : this.state.email,
      password : this.state.password,
      service_id : this.state.service_id,
      tier_id : this.state.tier_id,
    }

    subscriberAPI
      .saveSubscriber(postParams)
      .then(ret => {
        if (ret.data.status == 1) {
          let resetInput = {
            form_error: [], 
            success_message: ret.data.message,
            subscriber_birthday : this.state.subscriber_birthday,
            name : this.state.name,
            email_notification : this.state.email_notification,
            contact_number : this.state.contact_number,
            billing_address : this.state.billing_address,
            company_name : this.state.company_name,
            company_address : this.state.company_address,
            email : this.state.email,
            password : this.state.password,
            service_id : this.state.service_id,
            tier_id : this.state.tier_id,
            show: false,
            servicesOptions: [],
            tierOptions: [],
          }

          this.setState(resetInput);
          this.props.getSubscriberList();

        } else 
        {
          this.setState({ form_error: ret.data.errors, success_message: "", loading: false });
          this.props.getSubscriberList();
        }
        $(".btn-save-subscriver").removeAttr("disabled").html("Save");
      })
      .catch(error => {
        let errors = ["Network error, please reload the page or you have entered an invalid API"];
        this.setState({ form_error: errors, success_message: "" , loading: false});
        this.props.getSubscriberList();
        $(".btn-save-subscriver").removeAttr("disabled").html("Save");
      });
  };

  render() {
    return (
      <div>
        <Modal
          size="lg"
          show={this.state.show}
          onHide={this.handleCloseCreateSubscriberModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Subscriber</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.loading ? ( <Spinner /> ) : 
            <React.Fragment>

            <div className='row'>
              <div className='col-md-6'>
                <center>Basic Info<br /></center>
                <div className="col-md-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange("name")}
                  />
                </div>
                <div className="col-md-12">
                  <label>Date of birth</label>
                  <input
                    type="date"
                    className="form-control"
                    name="subscriber_birthday"
                    value={this.state.subscriber_birthday}
                    onChange={this.handleChange("subscriber_birthday")}
                  />
                </div>
                <div className="col-md-12">
                  <label>Email (notification)</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email_notification"
                    value={this.state.email_notification}
                    onChange={this.handleChange("email_notification")}
                  />
                </div>
                <div className="col-md-12">
                  <label>Contact Number</label>
                  <input
                    type="number"
                    className="form-control"
                    name="contact_number"
                    value={this.state.contact_number}
                    onChange={this.handleChange("contact_number")}
                  />
                </div>
                <div className="col-md-12">
                  <label>Billing Address </label>
                  <textarea
                    className="form-control"
                    name="billing_address"
                    value={this.state.billing_address}
                    onChange={this.handleChange("billing_address")}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <center>Company Info <br /></center>
                <div className="col-md-12">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="company_name"
                    value={this.state.company_name}
                    onChange={this.handleChange("company_name")}
                  />
                </div>
                <div className="col-md-12">
                  <label>Company Address </label>
                  <textarea
                    className="form-control"
                    name="company_address"
                    value={this.state.company_address}
                    onChange={this.handleChange("company_address")}
                  />
                </div>

                <center><br />Account</center>
                <div className="col-md-12">
                  <label>Username</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                  />
                </div>
                <div className="col-md-12">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                  />
                </div>

                <center><br /> Subscription Settings</center>
                <div className='col-md-12'>
                  <label>Services</label>
                  <Select options={this.state.servicesOptions} onChange={value => this.handleSelectService(value)} />
                </div>
                <div className='col-md-12'>
                  <label>Tier</label>
                  <Select  value={this.state.tierValue} options={this.state.tierOptions} onChange={value => this.handleSelectTier(value)} />
                </div>
              </div>
                
            </div>
              

              {this.state.form_error.map((errors, index) => (
                <React.Fragment key={index}>
                <div className="col-md-12">
                  <span className="text-danger">{errors}</span>{" "}
                </div>
                </React.Fragment>
              ))}
            </React.Fragment>   
            } 
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-save-subscriver" onClick={event => {this.saveSubscriberCreate();}}>Save</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default CreateSubscriberModal;
